/*
@File: Pluck Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - Partner CSS
** - About CSS
** - Features CSS
** - UX Research Process CSS
** - CTA CSS
** - Funfacts CSS
** - Feedback CSS
** - Pricing CSS
** - Blog CSS
** - Contact CSS
** - Subscribe CSS
** - Footer CSS
** - Go Top CSS
*/
/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

$font-family: 'Montserrat', sans-serif;
$main-1-color: #047a1c;
$main-2-color: #09b235;
$main-3-color: #06e935;
$white-color: #ffffff;
$white-2-color: #cccccc;
$paragraph-color: #646464;
$black-color: #383838;
$black-color-alt: #f9f9f9;
$gradient-color: linear-gradient(to right, $main-1-color, $main-2-color, $main-3-color);
$gradient-color2: linear-gradient(to left, transparent, #111111);
$font-size: 15px;
$transition: .5s;
$header-background-color: #383838;
$footer-background-color: #f9f9f9;

body {
  padding: 0;
  margin: 0;
  font-family: $font-family;
  font-size: $font-size;
}

a {
  display: block;
  outline: 0;
  box-shadow: unset;
  transition: $transition;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $main-2-color;
  }
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}

img {
  max-width: 100%;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.bg-fffcf4 {
  background-color: #fffcf4;
}

.bg-f7fafd {
  background-color: #f9f9f9;
}

.bg-e7f3ff {
  background-color: #e7f3ff;
}

.bg-f5fbff {
  background-color: #f5fbff;
}

.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-color;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

p {
  font-size: $font-size;
  color: $paragraph-color;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;

  &:last-child {
    margin-bottom: 0;
  }
}

/*btn btn-primary*/
.btn {
  font-weight: 800;
  border: none;
  padding: 14px 34px;
  font-size: $font-size;
  border-radius: 5px;
  transition: $transition;
}

.btn-primary {
  color: $white-color;
  background-color: $main-1-color;

  &:hover,
  &:focus,
  &.active {
    background-color: $main-3-color;
    color: $white-color;
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show>&.dropdown-toggle:focus {
    background-color: $black-color;
    color: $white-color;
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show>&.dropdown-toggle {
    background-color: $black-color;
    color: $white-color;
    box-shadow: unset;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    color: $white-color;
    background-color: $main-2-color;

    &:hover,
    &:focus {
      background-color: $black-color;
      color: $white-color;
      box-shadow: unset;
    }
  }
}

.btn-secondary {
  color: $main-2-color;
  background-color: $white-color;
  border: 1px solid $black-color-alt;

  &:hover,
  &:focus,
  &.active {
    background-color: $main-2-color;
    color: $white-color;
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show>&.dropdown-toggle:focus {
    background-color: $black-color;
    color: $white-color;
    box-shadow: unset;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show>&.dropdown-toggle {
    background-color: $black-color;
    color: $white-color;
    box-shadow: unset;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    color: $white-color;
    background-color: $main-2-color;

    &:hover,
    &:focus {
      background-color: $black-color;
      color: $white-color;
      box-shadow: unset;
    }
  }
}

.separator-area {
  height: 50px;
  background: $black-color;
}

.separator-small {
  height: 25px;
  background: $black-color;
}

/*section-title*/
.section-title {
  margin-bottom: 60px;

  h2 {
    position: relative;
    color: $black-color;
    text-transform: uppercase;
    text-align: center;
    text-decoration: solid underline $main-2-color;

    margin: {
      bottom: 30px;
      top: -5px;
    }

    font: {
      size: 30px;
      weight: 600;
    }
  }
}

/*freelancer-section-title*/
.about-section-title {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;

  h2 {
    margin: {
      bottom: 10px;
      top: -5px;
    }

    position: relative;
    z-index: 1;

    font: {
      size: 32px;
      weight: 600;
    }

    span {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-2-color 80%);
      background-size: 90px 1em !important;
    }
  }

  p {
    max-width: 520px;
    margin: 0 auto;
  }
}

/*saas-section-title*/
.saas-section-title {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 50px;

  h2 {
    margin: {
      bottom: 0;
      top: -5px;
      left: auto;
      right: auto;
    }

    max-width: 520px;

    font: {
      size: 28px;
      weight: 700;
    }
  }

  .bar {
    width: 70px;
    height: 3px;
    background: $main-2-color;
    position: relative;
    left: 0;
    right: 0;
    border-radius: 5px;
    margin: 15px auto 15px;
  }

  p {
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
  }
}

/*software-section-title*/
.software-section-title {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;

  h2 {
    margin: {
      bottom: 10px;
      top: -5px;
    }

    position: relative;
    z-index: 1;

    font: {
      size: 30px;
      weight: 600;
    }
  }

  p {
    max-width: 520px;
    margin: 0 auto;
  }

  img {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 20px;
    z-index: -1;
  }
}

/*app-section-title*/
.app-section-title {
  text-align: center;
  margin-bottom: 60px;

  h2 {
    margin: {
      bottom: 10px;
      top: -5px;
    }

    font: {
      size: 30px;
      weight: 600;
    }

    span {
      font-weight: 700;
    }
  }

  p {
    max-width: 520px;
    margin: 0 auto;
  }
}

/*lead-generation-section-title*/
.lead-generation-section-title {
  text-align: center;
  margin-bottom: 60px;

  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 10px;
    }
  }

  h2 {
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }

    max-width: 520px;

    font: {
      size: 28px;
      weight: 700;
    }
  }

  .bar {
    width: 70px;
    height: 3px;
    background: $main-2-color;
    position: relative;
    left: 0;
    right: 0;
    border-radius: 5px;
    margin: 15px auto 15px;
  }

  p {
    max-width: 520px;
    margin: 0 auto;
  }
}

/*car-subscription-section-title*/
.car-subscription-section-title {
  text-align: center;
  margin-bottom: 60px;

  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 10px;
    }
  }

  h2 {
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }

    max-width: 520px;

    font: {
      size: 30px;
      weight: 700;
    }
  }

  img {
    margin-top: 20px;
  }
}

/*erp-section-title*/
.erp-section-title {
  text-align: center;
  margin-bottom: 60px;

  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 10px;
    }
  }

  h2 {
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }

    max-width: 520px;

    font: {
      size: 30px;
      weight: 700;
    }
  }

  p {
    max-width: 520px;
    margin: 15px auto 0;
  }
}

/*form-control*/
.form-group {
  margin-bottom: 20px;
}

.form-control {
  height: 50px;
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid $main-2-color;
  border-radius: 0;
  transition: $transition;

  &:focus {
    border-color: $main-2-color;
    outline: 0;
    box-shadow: unset;
  }
}

/*================================================
Preloader CSS
=================================================*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $main-2-color;
  z-index: 999999;

  .spinner {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: -30px auto 0;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $white-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    animation-delay: -1.0s;
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
}

/*================================================
Navbar CSS
=================================================*/
.navbar-style-one {
  &.bg-light {
    background-color: $header-background-color !important;
  }

  &.navbar {
    position: relative;
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 99;
    padding: 0;

    &.is-sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;
      z-index: 101;
      box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
      background-color: $white-color !important;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
  }

  &.navbar-light {
    .navbar-nav {
      .nav-item {
        padding: {
          left: 15px;
          right: 15px;
        }

        .nav-link {
          padding: 0 7px;
          position: relative;
          color: $white-color;
          z-index: 1;

          font: {
            size: $font-size;
            weight: 600;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -5px;
            width: 100%;
            height: 2px;
            z-index: -1;
            transition: $transition;
            opacity: 0;
            visibility: hidden;

            background: {
              color: $main-2-color;
              position: center center;
              size: cover;
              repeat: no-repeat;
            }
          }

          &:hover,
          &:focus,
          &.active {
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &.active {
          .nav-link {
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .navbar-brand {
      img {
        &:last-child {
          display: none;
        }
      }
    }

    .others-option {
      padding: 0;
      list-style-type: none;
      margin: 0 0 0 auto;

      .btn-primary {
        background: $main-2-color;
        padding: 6px 20px;
        font-weight: 600;

        &:hover,
        &:focus,
        &.active {
          background-color: $main-3-color;
          color: $white-color;
          box-shadow: unset;
          border-color: $main-2-color;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        .show>&.dropdown-toggle:focus {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
          box-shadow: unset;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show>&.dropdown-toggle {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
          box-shadow: unset;
        }
      }
    }

    &.is-sticky {
      .navbar-brand {
        img {
          &:last-child {
            display: block;
          }

          &:first-child {
            display: none;
          }
        }
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $black-color;
          }

          &.active {
            .nav-link {
              &::before {
                opacity: 1;
                visibility: visible;
              }
            }
          }

          &:last-child {
            padding-right: 0;
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }

      .others-option {
        .btn-primary {

          &:hover,
          &:focus,
          &.active {
            color: $black-color;
            box-shadow: unset;
          }

          &:not(:disabled):not(.disabled).active:focus,
          &:not(:disabled):not(.disabled):active:focus,
          .show>&.dropdown-toggle:focus {
            background-color: $main-2-color;
            color: $white-color;
            border-color: $main-2-color;
            box-shadow: unset;
          }

          &:not(:disabled):not(.disabled).active,
          &:not(:disabled):not(.disabled):active,
          .show>&.dropdown-toggle {
            background-color: $main-2-color;
            color: $white-color;
            border-color: $main-2-color;
            box-shadow: unset;
          }
        }
      }
    }
  }
}

/*navbar-style-two*/
.navbar-style-two {
  &.bg-light {
    background-color: $header-background-color !important;
  }

  &.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 99;
    padding: 0;

    &.is-sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;
      z-index: 999;
      box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
      background-color: $white-color !important;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
  }

  &.navbar-light {
    .navbar-nav {
      .nav-item {
        padding: {
          left: 9px;
          right: 9px;
        }

        .nav-link {
          padding: 0 7px;
          position: relative;
          text-transform: lowercase;
          color: $black-color-alt;
          z-index: 1;

          font: {
            size: $font-size;
            weight: 600;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -5px;
            width: 100%;
            height: 10px;
            z-index: -1;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
            background: url(../../images/color-bar.png);

            background: {
              position: center center;
              size: cover;
              repeat: no-repeat;
            }
          }

          &:hover,
          &:focus,
          &.active {
            color: $black-color;

            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &.active {
          .nav-link {
            color: $black-color;

            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .others-option {
      padding: 0;
      list-style-type: none;
      margin: 0 0 0 auto;

      .btn-primary {
        background: transparent;
        border: 1px solid $black-color-alt;
        padding: 11px 34px;
        color: $black-color-alt;

        &:hover,
        &:focus,
        &.active {
          background-color: $main-2-color;
          color: $white-color;
          box-shadow: unset;
          border-color: $main-2-color;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        .show>&.dropdown-toggle:focus {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
          box-shadow: unset;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show>&.dropdown-toggle {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
          box-shadow: unset;
        }
      }
    }
  }
}

/*navbar-style-three*/
.navbar-style-three {
  &.bg-light {
    background-color: $header-background-color !important;
  }

  &.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 99;
    padding: 0
  }
}

/*navbar-style-four*/
.navbar-style-four {
  &.bg-light {
    background-color: $header-background-color !important;
  }

  &.navbar {
    position: absolute;
    top: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0;

    &.is-sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;
      z-index: 999;
      box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
      background-color: $white-color !important;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
  }

  &.navbar-light {
    .navbar-nav {
      .nav-item {
        padding: {
          left: 9px;
          right: 9px;
        }

        .nav-link {
          padding: 0 7px;
          position: relative;
          text-transform: lowercase;
          color: $black-color;
          z-index: 1;

          font: {
            size: $font-size;
            weight: 600;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 1px;
            width: 100%;
            height: 10px;
            z-index: -1;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
            background: url(../../images/color-bar.png);

            background: {
              position: center center;
              size: cover;
              repeat: no-repeat;
            }
          }

          &:hover,
          &:focus,
          &.active {
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &.active {
          .nav-link {
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .others-option {
      padding: 0;
      list-style-type: none;
      margin: 0 0 0 auto;

      .btn-primary {
        padding: 11px 34px;
      }
    }
  }
}

/*navbar-style-five*/
.navbar-style-five {
  &.bg-light {
    background-color: $header-background-color !important;
  }

  &.navbar {
    padding: 0;

    &.is-sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0;
      z-index: 999;
      box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
      background-color: $white-color !important;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
  }

  &.navbar-light {
    .navbar-nav {
      .nav-item {
        padding: {
          left: 9px;
          right: 9px;
        }

        .nav-link {
          padding: 0 7px;
          position: relative;
          text-transform: capitalize;
          color: $black-color;
          z-index: 1;

          font: {
            size: $font-size;
            weight: 600;
          }

          &:hover,
          &:focus,
          &.active {
            color: $main-2-color;
          }
        }

        &.active {
          .nav-link {
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .others-option {
      padding: 0;
      list-style-type: none;
      margin: 0 0 0 auto;

      .btn-primary {
        text-transform: capitalize;
        padding: 11px 34px;
      }
    }
  }
}

.navbar-brand {
  img {
    height: 50px;
  }
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
  height: 100vh;
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/agency-main-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $black-color;
    opacity: .55;
  }
}

.main-banner-content {
  max-width: 700px;
  margin: 70px auto 0;
  text-align: center;

  h1 {
    color: $white-color;
    margin: 0;

    font: {
      size: 45px;
      weight: 700;
    }
  }

  p {
    color: $white-color;
    max-width: 600px;
    margin: 20px auto 35px;
    text-align: center;
  }

  .btn-box {
    .btn-primary {
      margin-right: 10px;

      &:hover,
      &:focus,
      &.active {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      .show>&.dropdown-toggle:focus {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show>&.dropdown-toggle {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }
    }

    .video-btn {
      display: inline-block;
      color: $white-color;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 10px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $white-color;
        transition: $transition;
      }

      &:hover,
      &:focus {
        color: $main-2-color;

        i {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*freelancer-banner*/
.about-banner {
  height: 730px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .back-text {
    position: absolute;
    right: 20%;
    z-index: -1;
    top: 50%;
    transform: rotate(-40deg);
    margin-top: -75px;

    h1 {
      font-size: 155px;
      font-weight: 700;
      color: #3b3b3b;
      opacity: .10;
    }
  }
}

.about-banner-content {
  margin-top: 70px;
  text-align: center;

  h1 {
    margin: 0;

    font: {
      size: 55px;
      weight: 700;
    }

    span {
      background: {
        image: url(../../images/bar.png);
        position: center center;
        size: contain;
        repeat: no-repeat;
      }
    }
  }

  ul {
    margin: 20px 0 35px;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin-right: 15px;

      a {
        font-size: 25px;
        color: #6d6d6d;

        &:hover,
        &:focus {
          color: $main-2-color;
          transform: translateY(-3px);
        }
      }
    }
  }

  .btn-box {
    .video-btn {
      display: inline-block;
      color: #999999;
      margin-left: 30px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      border: 1px solid #999999;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: 4px;

      &:hover,
      &:focus {
        background-color: $main-2-color;
        color: $white-color;
        border-color: $main-2-color;
      }
    }
  }
}

.about-banner-image {
  text-align: center;
}

/*services*/
.services-banner {
  height: 730px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .back-text {
    position: absolute;
    right: 20%;
    z-index: -1;
    top: 50%;
    transform: rotate(-40deg);
    margin-top: -75px;

    h1 {
      font-size: 155px;
      font-weight: 700;
      color: #3b3b3b;
      opacity: .10;
    }
  }
}

.services-banner-content {
  margin-top: 70px;
  text-align: center;

  h1 {
    margin: 0;

    font: {
      size: 55px;
      weight: 700;
    }

    span {
      background: {
        image: url(../../images/bar.png);
        position: center center;
        size: contain;
        repeat: no-repeat;
      }
    }
  }

  ul {
    margin: 20px 0 35px;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin-right: 15px;

      a {
        font-size: 25px;
        color: #6d6d6d;

        &:hover,
        &:focus {
          color: $main-2-color;
          transform: translateY(-3px);
        }
      }
    }
  }

  .btn-box {
    .video-btn {
      display: inline-block;
      color: #999999;
      margin-left: 30px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      border: 1px solid #999999;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: 4px;

      &:hover,
      &:focus {
        background-color: $main-2-color;
        color: $white-color;
        border-color: $main-2-color;
      }
    }
  }
}

.services-banner-image {
  text-align: center;
}

/*saas-banner*/
.saas-banner {
  height: 1150px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .effect {
    position: absolute;
    top: -30%;
    left: -40%;
    bottom: 15%;
    right: -40%;
    z-index: -1;
    transform: rotate(8deg);
    background: $gradient-color;
    pointer-events: none;
  }
}

.saas-banner-content {
  max-width: 700px;
  text-align: center;
  margin: -350px auto 0;

  h1 {
    color: $white-color;
    margin: 0;

    font: {
      size: 45px;
      weight: 700;
    }
  }

  p {
    color: $white-color;
    max-width: 520px;
    margin: 20px auto 35px;
  }

  .btn-box {
    .btn-primary {
      margin-right: 10px;
      background-color: $white-color;
      color: $black-color;
      font-weight: 600;

      &:hover,
      &:focus,
      &.active {
        background-color: $main-2-color;
        color: $white-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      .show>&.dropdown-toggle:focus {
        background-color: $main-2-color;
        color: $white-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show>&.dropdown-toggle {
        background-color: $main-2-color;
        color: $white-color;
        box-shadow: unset;
      }
    }

    .video-btn {
      display: inline-block;
      color: $white-color;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 10px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $white-color;
        transition: $transition;
      }

      &:hover,
      &:focus {
        i {
          background-color: $black-color;
          color: $white-color;
          border-color: $black-color;
        }
      }
    }
  }
}

.saas-banner-image {
  position: absolute;
  right: 0;
  margin: 0 auto;
  text-align: center;
  left: 0;
  bottom: 0;
  z-index: 3;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 0;
}

/*software-banner*/
.software-banner {
  height: 730px;
}

.software-banner-content {
  margin-top: 70px;

  h1 {
    margin: 0;

    font: {
      size: 36px;
      weight: 700;
    }

    span {
      background: {
        image: url(../../images/bar.png);
        position: center center;
        size: cover;
        repeat: no-repeat;
      }
    }
  }

  p {
    margin: 15px 0 35px;
  }

  .btn-box {
    a {
      display: inline-block;
    }

    .video-btn {
      display: inline-block;
      color: #999999;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 30px;
      position: relative;
      top: 5px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid #999999;
        transition: $transition;
      }

      &:hover,
      &:focus {
        color: $main-2-color;

        i {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*app-banner*/
.app-banner {
  height: 850px;
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/agency-main-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $gradient-color2;
    opacity: .88;
  }
}

.app-banner-image {
  margin-top: 80px;
  text-align: right;
}

.app-banner-content {
  margin-top: 70px;

  h1 {
    color: $white-color;
    margin: 0;

    font: {
      size: 45px;
      weight: 700;
    }
  }

  p {
    color: $white-color;
    max-width: 520px;
    margin: 20px 0 35px;
  }

  .btn-box {
    .btn-primary {
      margin-right: 10px;

      &:hover,
      &:focus,
      &.active {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      .show>&.dropdown-toggle:focus {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show>&.dropdown-toggle {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }
    }

    .video-btn {
      display: inline-block;
      color: $white-color;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 10px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $white-color;
        transition: $transition;
      }

      &:hover,
      &:focus {
        color: $main-2-color;

        i {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*lead-generation-banner*/
.lead-generation-banner {
  height: 880px;
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/agency-main-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $gradient-color2;
    opacity: .88;
  }
}

.lead-generation-banner-content {
  h1 {
    padding-bottom: 15px;
    color: $white-color;
    margin: 0;

    font: {
      // size: 45px;
      size: 30px;
      weight: 700;
    }

    b {
      color: $main-3-color;
    }
  }

  p {
    color: $white-color;
    // max-width: 520px;
    margin: 20px 0 35px;
  }

  ul {
    color: white;
    padding-top: 20px;
    font-size: 25px;
    font-weight: 600;


  }

  .btn-box {
    .btn-primary {
      margin-right: 10px;

      &:hover,
      &:focus,
      &.active {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      .show>&.dropdown-toggle:focus {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show>&.dropdown-toggle {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }
    }

    .video-btn {
      display: inline-block;
      color: $white-color;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 10px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $white-color;
        transition: $transition;
      }

      &:hover,
      &:focus {
        color: $main-2-color;

        i {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }
}

.lead-generation-form {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding-bottom: 40px;
  position: relative;
  text-align: center;
  margin-left: 10px;
  background-color: $white-color;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background: $white-color;
    width: 96%;
    opacity: .62;
    height: 50%;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 3px;
  }

  .form-header {
    background-color: $main-2-color;
    padding: 30px 20px;

    h3 {
      font: {
        size: 28px;
        weight: 600;
      }

      color: $white-color;
      margin-bottom: 10px;
    }

    span {
      display: block;
      color: $white-color;
      opacity: .90;
      font-size: 14px;
    }
  }

  form {
    padding: 40px 30px 20px;

    .form-control {
      font-size: 13px;
      padding-left: 10px;
    }

    .btn {
      display: block;
      width: 100%;
      padding: 16px 34px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  p {
    font-size: 13px;

    i {
      color: red;
      margin: 3px;
    }
  }
}

/*car-subscription-banner*/
.car-subscription-banner {
  height: 750px;
  position: relative;
  background: transparent url(../../images/map2.png) no-repeat;
  background-size: cover;

}

.car-subscription-banner-content {
  margin-top: 70px;

  h1 {
    margin: 0;

    font: {
      size: 48px;
      weight: 700;
    }
  }

  p {
    max-width: 520px;
    margin: 20px 0 35px;
    opacity: .90;
  }

  form {
    position: relative;

    .form-control {
      height: 75px;
      border: none;
      background: $white-color;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 7px 25px;
    }

    .btn {
      position: absolute;
      border-radius: 0;
      height: 65px;
      top: 50%;
      font-weight: 600;
      transform: translateY(-50%);
      right: 5px;
    }
  }
}

// .car-subscription-banner-image {
//     // // position: absolute;

//     // right: 40px;
//     // bottom: 5px;
//     // max-width: 600px;
//     width: auto;
//     height: auto;
// }
/*services-banner*/
.services-banner {
  height: 100vh;
  position: relative;
  z-index: 1;

  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $gradient-color2;
    opacity: .6;
  }
}

/*Background image services*/
.mail-one {
  background: {
    image: url(../../images/erp-banner.jpg);
  }
}

.mail-two {
  background: {
    image: url(../../images/erp-banner.jpg);
  }
}

.mail-three {
  background: {
    image: url(../../images/erp-banner.jpg);
  }
}

.mail-four {
  background: {
    image: url(../../images/erp-banner.jpg);
  }
}

.erp-one {
  background: {
    image: url(../../images/erp-banner.jpg);
  }
}

.services-banner-image {
  margin-top: 80px;
  text-align: right;
}

.services-banner-content {
  margin-top: 70px;

  h1 {
    color: $white-color;
    margin: 0;

    font: {
      size: 45px;
      weight: 700;
    }
  }

  p {
    color: $white-color;
    max-width: 520px;
    margin: 20px 0 35px;
  }

  .btn-box {
    .btn-primary {
      margin-right: 10px;

      &:hover,
      &:focus,
      &.active {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active:focus,
      &:not(:disabled):not(.disabled):active:focus,
      .show>&.dropdown-toggle:focus {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }

      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active,
      .show>&.dropdown-toggle {
        background-color: $white-color;
        color: $black-color;
        box-shadow: unset;
      }
    }

    .video-btn {
      display: inline-block;
      color: $white-color;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 10px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $white-color;
        transition: $transition;
      }

      &:hover,
      &:focus {
        color: $main-2-color;

        i {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*================================================
Partner CSS
=================================================*/
.partners {
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 33px;
  float: right;

  .partner-title {
    text-align: center;
  }

  .partner-item {
    display: inline-flex;

    img {
      height: 40px;

      &.first {
        padding-right: 0;
      }

      &.second {
        padding-right: 30px;
      }
    }
  }
}

.partner-area {
  background: $black-color;
  text-align: center;
  padding: 0;

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    color: $white-color;
    text-transform: lowercase;
    margin-bottom: 40px;
  }

  .partner-item {
    margin: 10px;
    float: right;

    img {
      height: 50px;
    }
  }
}

.partner-slides {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: unset;
        background: white;
        padding: 5px;
        height: 50px;
      }
    }
  }
}

/*partner-style-two*/
.partner-area-two {
  background-color: #fffcf4;
  text-align: center;

  padding: {
    top: 40px;
    bottom: 40px;
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    text-transform: lowercase;
    margin-bottom: 40px;
  }

  .partner-item {
    img {
      opacity: .50;
      transition: $transition;
    }

    &:hover,
    &:focus {
      img {
        opacity: 1;
      }
    }
  }
}

/*partner-style-three*/
.partner-area-three {
  text-align: center;

  padding: {
    top: 50px;
    bottom: 100px;
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    text-transform: lowercase;
    margin-bottom: 50px;
  }

  .partner-item {
    img {
      opacity: .50;
      transition: $transition;
    }

    &:hover,
    &:focus {
      img {
        opacity: 1;
      }
    }
  }
}

/*partner-style-four*/
.partner-area-four {
  text-align: center;

  padding: {
    top: 15px;
    bottom: 15px;
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin-bottom: 30px;
  }

  .partner-item {
    img {
      opacity: .50;
      transition: $transition;
    }

    &:hover,
    &:focus {
      img {
        opacity: 1;
      }
    }
  }
}

/*================================================
About CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}

.circle {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
  vertical-align: middle;
}

.about-content {
  h2 {
    font: {
      size: 30px;
      weight: 600;
    }
  }

  p {
    margin-bottom: 0;
  }

  .section-title {
    margin-bottom: 0;
  }

  .subtitle {
    padding-top: 30px;
    font-size: 40px;
    font-weight: bold;
  }

  .single-inner-content {
    margin-top: 30px;

    .title {
      position: relative;
      margin-bottom: 15px;

      padding: {
        left: 35px;
        top: 5px;
      }

      .icon {
        font-size: 20px;
        color: $main-2-color;
        position: absolute;
        left: 0;
        top: 0;
      }

      h3 {
        font: {
          size: 15px;
          weight: 600;
        }

        margin-bottom: 0;
      }
    }
  }

  .btn {
    margin-top: 25px;
  }
}

/*freelancer-about-css*/
.about-area-two {
  padding-bottom: 50px;
}

.freelancer-about-content {
  h3 {
    font: {
      size: 28px;
      weight: 700;
    }

    margin-bottom: 15px;
  }

  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 18px;
      weight: 600;
    }

    margin-bottom: 15px;
  }

  p {
    margin-bottom: 0;
  }
}

/*app-about-css*/
.single-about-box {
  margin-bottom: 30px;
  text-align: center;
  padding: 30px;
  background: $white-color;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  transition: $transition;

  .icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    background: #edf5ff;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    color: $main-2-color;
    transition: $transition;
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin: 25px 0 15px;
  }

  &:hover {
    transform: translateY(-10px);

    .icon {
      color: $white-color;
      background: $main-2-color;
    }
  }
}

.about-inner-area {
  margin-top: 60px;

  .about-inner-content {
    max-width: 540px;

    .about-item {
      margin-bottom: 35px;
      position: relative;
      padding-left: 90px;
      transition: $transition;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 70px;
        line-height: 75px;
        background: $white-color;
        border-radius: 50%;
        font-size: 25px;
        text-align: center;
        color: $main-2-color;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1) !important;
        transition: $transition;
      }

      h3 {
        font: {
          size: 20px;
          weight: 600;
        }

        margin: 0 0 15px;
      }

      &:hover {
        transform: translateY(-10px);

        .icon {
          color: $white-color;
          background: $main-2-color;
        }
      }
    }
  }
}

/*================================================
Services CSS
=================================================*/
.single-services {
  display: table;
  position: relative;
  z-index: 1;
  transition: $transition;
  border: 1px solid #eeeeee;
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 140px;

  .icon {
    padding: 15px;
    text-align: center;
  }

  h3 {
    font: {
      size: 16px;
      weight: 600;
    }

    margin-bottom: 12px;
    transition: $transition;
    text-align: center;
  }

  i {
    font-size: 40px;
    color: $main-2-color;
    transition: $transition;
  }

  p {
    display: none;
    margin-bottom: 0;
    transition: $transition;
    text-align: center;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $main-2-color;
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  &:hover,
  &:focus {
    border-color: $main-2-color;

    .icon {
      display: none;
    }

    &::before {
      transform: scaleY(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }

    i {
      color: $white-color;
    }

    h3 {
      color: $white-color;
      display: none;
    }

    p {
      display: table-cell;
      width: 100%;
      height: 110px;
      vertical-align: middle;
      color: $white-color;
      opacity: .88;
    }
  }
}

.single-servicess {
  position: relative;
  z-index: 1;
  transition: $transition;
  border: 1px solid #eeeeee;
  padding: 30px 20px 30px 60px;
  margin-bottom: 20px;

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin-bottom: 12px;
    transition: $transition;
  }

  i {
    position: absolute;
    left: 20px;
    top: 33px;
    font-size: 20px;
    color: $main-2-color;
    transition: $transition;
  }

  p {
    margin-bottom: 0;
    transition: $transition;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $main-2-color;
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }

  &:hover,
  &:focus {
    border-color: $main-2-color;

    &::before {
      transform: scaleY(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }

    i {
      color: $white-color;
    }

    h3 {
      color: $white-color;
    }

    p {
      color: $white-color;
      opacity: .88;
    }
  }
}

.services-inner-area {
  margin-top: 60px;

  .services-inner-content {
    max-width: 540px;

    .services-item {
      margin-bottom: 35px;
      position: relative;
      padding-left: 90px;
      transition: $transition;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
        height: 70px;
        line-height: 75px;
        background: $white-color;
        border-radius: 50%;
        font-size: 25px;
        text-align: center;
        color: $main-2-color;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, .1) !important;
        transition: $transition;
      }

      h3 {
        font: {
          size: 20px;
          weight: 600;
        }

        margin: 0 0 15px;
      }

      &:hover {
        transform: translateY(-10px);

        .icon {
          color: $white-color;
          background: $main-2-color;
        }
      }
    }
  }
}

.services-slides {
  &.owl-theme {
    .owl-nav {
      &.disabled+ {
        .owl-dots {
          margin-top: 30px;
          line-height: .01;
        }
      }
    }

    .owl-dots {
      .owl-dot {
        outline: 0;
        box-shadow: unset;

        span {
          width: 13px;
          height: 13px;
          margin: 0 4px;
          background: #dddddd;
          transition: $transition;
          border-radius: 50%;

          &:hover,
          &:focus {
            background: $main-2-color;
          }
        }

        &.active {
          span {
            background: $main-2-color;
          }
        }
      }
    }
  }
}

/*services-style-two*/
.services-area-two {
  padding-bottom: 70px;
}

.services-box {
  margin-bottom: 30px;
  border-radius: 2px;

  img {
    border-radius: 2px 2px 0 0;
  }

  .services-content {
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    background-color: $white-color;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 35px 25px;

    margin: {
      top: -30px;
      left: 15px;
      right: 15px;
    }

    h3 {
      font: {
        size: 20px;
        weight: 600;
      }

      margin-bottom: 12px;
    }

    p {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin: 20px 0 0;
      list-style-type: none;

      li {
        font-size: 12px;
        color: $main-2-color;
        background: #f7e7eb;
        padding: 5px 12px;
        border-radius: 3px;
        display: inline-block;
        margin-right: 5px;

        &.bg-cdf1d8 {
          background-color: #cdf1d8;
          color: #44ce6f;
        }

        &.bg-f78acb {
          background-color: rgba(247, 138, 203, 0.3);
          color: #f78acb;
        }

        &.bg-c679e3 {
          background-color: #edc3fc;
          color: #c679e3;
        }

        &.bg-eb6b3d {
          background-color: rgba(235, 107, 61, 0.3);
          color: #eb6b3d;
        }
      }
    }
  }
}

/*================================================
How Its Work CSS
=================================================*/
.how-its-work-tab {
  &.tab {
    .tabs {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 20px;

        a {
          color: #999999;
          font-weight: 500;
          padding-left: 25px;
          position: relative;
          display: inline-block;

          i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            content: '';
            position: absolute;
            left: 25px;
            bottom: -1px;
            width: 0;
            transition: $transition;
            background: $main-2-color;
            height: 2px;
            opacity: 0;
            visibility: hidden;
          }

          &:hover,
          &:focus {
            color: $main-2-color;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 80%;
            }
          }
        }

        &.current {
          a {
            color: $main-2-color;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 80%;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .tabs_item {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}

/*how-its-work-style-two*/
.single-work-process {
  background: $white-color;
  position: relative;
  transition: $transition;
  padding: 30px 25px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.02);
  text-align: center;

  span {
    display: block;
    color: $main-2-color;

    font: {
      weight: 700;
      size: 20px;
    }

    margin-bottom: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    background: url(../../images/arrow-big2.png) no-repeat center center;
    width: 217px;
    height: 210px;
    top: -50px;
    right: -115px;
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin-bottom: 15px;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.07);
  }
}

.col-lg-4 {
  &:last-child {
    .single-work-process {
      &::before {
        display: none;
      }
    }
  }
}

.alert-info-box {
  text-align: center;
  margin-top: 50px;

  padding: {
    top: 25px;
    bottom: 25px;
  }

  border: {
    top: 1px solid #eeeeee;
    bottom: 1px solid #eeeeee;
  }

  a {
    font-weight: 600;
    color: $main-2-color;
  }
}

/*================================================
Features CSS
=================================================*/
.features-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;

  .section-title {
    margin-bottom: 25px;
  }

  .software-section-title {
    margin-bottom: 25px;
  }
}

.single-features {
  text-align: center;
  transition: $transition;
  padding: 45px 20px 30px;

  .icon {
    width: 80px;
    height: 80px;
    line-height: 80px;
    display: inline-block;
    background-color: $main-2-color;
    position: relative;
    color: $white-color;
    z-index: 1;
    transition: $transition;
    font-size: 22px;
    border-radius: 57px;
    transform: rotate(45deg);
    box-shadow: 5px 5px 1px 0 rgba(0, 0, 0, 0.1);

    i {
      transform: rotate(-45deg);
      transition: $transition;
    }

    &.bg1 {
      background: linear-gradient(to bottom, $main-3-color, $main-2-color, $main-1-color);
    }

    &.bg2 {
      background: linear-gradient(to bottom, $main-3-color, $main-2-color, $main-1-color);
    }

    &.bg3 {
      background: linear-gradient(to bottom, $main-3-color, $main-2-color, $main-1-color);
    }

    &.bg4 {
      background: linear-gradient(to bottom, $main-3-color, $main-2-color, $main-1-color);
    }

    &.bg5 {
      background: linear-gradient(to bottom, $main-3-color, $main-2-color, $main-1-color);
    }

    &.bg6 {
      background: linear-gradient(to bottom, $main-3-color, $main-2-color, $main-1-color);
    }
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin: {
      top: 45px;
      bottom: 15px;
    }
  }

  p {
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 14px;
    text-align: center;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: $white-color;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    .icon {
      transform: rotate(135deg);

      i {
        transform: rotate(-135deg);
      }
    }
  }
}

.features-content {
  .row {
    margin: {
      left: -5px;
      right: -5px;
    }

    .col-lg-6 {
      padding: {
        left: 5px;
        right: 5px;
      }
    }
  }

  .box {
    position: relative;
    margin-top: 25px;
    display: block;
    padding: 15px 15px 15px 40px;
    color: #6084a4;

    font: {
      weight: 600;
      size: 15px;
    }

    border-radius: 3px;
    transition: $transition;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    i {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover,
    &:focus {
      background-color: $main-2-color;
      transform: translateY(-5px);
      color: $white-color;
    }
  }
}

.features-image {
  text-align: center;
  position: relative;

  .rotate-image {
    position: absolute;
    top: -55px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.rotateme {
  animation-name: rotateme;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*app-features-css*/
.features-tab {
  &.tab {
    .tabs {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 20px;

        a {
          color: #999999;
          font-weight: 500;
          padding-left: 25px;
          position: relative;
          display: inline-block;

          i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            content: '';
            position: absolute;
            left: 25px;
            bottom: -1px;
            width: 0;
            transition: $transition;
            background: $main-2-color;
            height: 2px;
            opacity: 0;
            visibility: hidden;
          }

          &:hover,
          &:focus {
            color: $main-2-color;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 80%;
            }
          }
        }

        &.current {
          a {
            color: $main-2-color;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 80%;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .tabs_item {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}

/*lead-generation-features-css*/
.lead-generation-features-content {
  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 10px;
    }
  }

  h2 {
    margin: {
      bottom: 15px;
    }

    font: {
      size: 28px;
      weight: 700;
    }
  }

  p {
    margin: 0;
  }

  .box {
    margin-top: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: $white-color;
    padding: 25px;
    transition: $transition;
    box-shadow: 0px 0px 50px 30px rgba(34, 34, 34, 0.05);

    i {
      color: $main-2-color;
      font-size: 35px;
      transition: $transition;
    }

    h3 {
      font: {
        size: 16px;
        weight: 600;
      }

      margin: {
        top: 15px;
        bottom: 0;
      }

      transition: $transition;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $main-2-color;
      left: 0;
      height: 2px;
      transition: $transition;
      z-index: -1;
    }

    &:hover,
    :focus {
      transform: translateY(-5px);

      &::before {
        height: 100%;
      }

      h3 {
        color: $white-color;
      }

      i {
        color: $white-color;
      }
    }
  }
}

/*car-subscription-features-css*/
.car-subscription-features-content {
  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 10px;
    }
  }

  h2 {
    margin: {
      bottom: 15px;
    }

    font: {
      size: 28px;
      weight: 700;
    }
  }

  p {
    margin: 0;
  }

  .box {
    margin-top: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: $white-color;
    padding: 25px;
    transition: $transition;
    box-shadow: 0px 0px 50px 30px rgba(34, 34, 34, 0.05);

    i {
      color: $main-2-color;
      font-size: 35px;
      transition: $transition;
    }

    h3 {
      font: {
        size: 16px;
        weight: 600;
      }

      margin: {
        top: 15px;
        bottom: 0;
      }

      transition: $transition;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $main-2-color;
      left: 0;
      height: 2px;
      transition: $transition;
      z-index: -1;
    }

    &:hover,
    :focus {
      transform: translateY(-5px);

      &::before {
        height: 100%;
      }

      h3 {
        color: $white-color;
      }

      i {
        color: $white-color;
      }
    }
  }
}

/*erp-features-css*/
.erp-features {
  padding-bottom: 70px;
}

.single-features-box {
  background: $white-color;
  text-align: center;
  position: relative;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  z-index: 1;
  transition: $transition;
  padding: 45px 25px;
  margin-bottom: 30px;

  i {
    font-size: 35px;
    transition: $transition;
  }

  h3 {
    font: {
      size: 18px;
      weight: 600;
    }

    margin: 25px 0 0;
    transition: $transition;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    background: $main-2-color;
    z-index: -1;
    transition: $transition;
    width: 100%;
  }

  &:hover,
  &:focus {
    transform: translateY(-5px);

    &::before {
      height: 100%;
    }

    h3 {
      color: $white-color;
    }

    i {
      color: $white-color;
    }
  }
}

/*================================================
New Features Update CSS
=================================================*/
.new-features-update {
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    background: $gradient-color;
    position: absolute;
    top: -50%;
    height: 130%;
    left: 0;
    z-index: -1;
    width: 100%;
    content: '';
    transform: skewY(169deg);
  }

  .saas-section-title {
    h2 {
      color: $white-color;
    }

    .bar {
      background: $main-2-color;
    }

    p {
      color: $white-color;
      opacity: .88;
    }
  }

  .erp-section-title {
    h2 {
      color: $white-color;
    }

    span {
      color: $white-color;
      opacity: .88;
    }

    p {
      color: $white-color;
      opacity: .88;
    }
  }
}

.new-features-update-tab {
  &.tab {
    .tabs {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 20px;

        a {
          color: $white-color;
          font-weight: 500;
          padding-left: 25px;
          position: relative;
          display: inline-block;

          i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            content: '';
            position: absolute;
            left: 25px;
            bottom: -1px;
            width: 0;
            transition: $transition;
            background: $main-2-color;
            height: 2px;
            opacity: 0;
            visibility: hidden;
          }

          &:hover,
          &:focus {
            color: $white-color;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 80%;
            }
          }
        }

        &.current {
          a {
            color: $white-color;

            &::before {
              opacity: 1;
              visibility: visible;
              width: 80%;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .tabs_item {
      display: none;

      &:first-child {
        display: block;
      }
    }

    .button-container {
      margin-top: 30px;
      text-align: center;

      .btn-primary {
        background-color: $white-color;
        color: $main-2-color;

        &:hover,
        &:focus {
          background-color: $black-color;
          color: $white-color;
        }
      }
    }
  }
}

/*================================================
Featured Cars CSS
=================================================*/
.featured-cars {
  .car-subscription-section-title {
    text-align: left;

    h2 {
      margin: {
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.single-featured-cars {
  text-align: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(transparent 0%, transparent 40%, rgba(0, 0, 0, 0.4) 58%, rgba(0, 0, 0, 0.9) 89%, rgba(0, 0, 0, 0.73) 100%);
    width: 100%;
    height: 100%;
  }

  .featured-cars-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    right: 0;
    margin: 0 auto;
    transition: $transition;
    padding: 20px;

    h3 {
      margin-bottom: 0;

      font: {
        size: 20px;
        weight: 600;
      }

      a {
        color: $white-color;
      }
    }

    span {
      display: block;
      color: $white-color;
      margin-top: 12px;
    }
  }

  &:hover,
  &:focus {
    .featured-cars-content {
      bottom: 10px;
    }
  }
}

.featured-cars-slides {
  &.owl-theme {
    .owl-nav {
      margin-top: 0;
      line-height: .01;
      position: absolute;
      right: 15px;
      top: 0;
      margin-top: -100px;

      [class*=owl-] {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
        background-color: $white-color;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        transition: $transition;
        color: #8f8f8f;
        margin: 0;

        &:hover,
        &:focus {
          outline: 0;
          box-shadow: unset;
          background-color: $main-2-color;
          color: $white-color;
        }

        &.owl-next {
          margin-left: 5px;
        }
      }
    }
  }
}

/*================================================
Boxes CSS
=================================================*/
.boxes-area {
  background-color: $main-2-color;
}

.single-box {
  position: relative;
  z-index: 1;
  padding: 35px 30px;
  background-color: $white-color;
  // box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin: 20px 0 20px;

  &::before {
    content: '';
    position: absolute;
    left: 10px;
    margin: 0 auto;
    text-align: center;
    top: -10px;
    width: 94%;
    z-index: -1;
    height: 107%;
    background: $white-color;
    opacity: .57;
  }

  &::after {
    content: '';
    position: absolute;
    left: 20px;
    margin: 0 auto;
    text-align: center;
    top: -20px;
    width: 88%;
    z-index: -1;
    height: 114%;
    background: $white-color;
    opacity: .57;
  }

  i {
    font-size: 35px;
    color: $main-2-color;
  }

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin: 25px 0 22px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -7px;
      height: 2px;
      background: $main-2-color;
      transition: $transition;
      width: 50px;
    }
  }

  &:hover,
  &:focus {
    h3 {
      &::before {
        width: 70px;
      }
    }
  }
}

/*================================================
UX Research Process CSS
=================================================*/
.research-process-content {
  h2 {
    font: {
      size: 30px;
      weight: 600;
    }

    margin-bottom: 15px;
  }

  p {
    margin-bottom: 0;
  }

  .single-process {
    position: relative;
    margin-top: 25px;

    padding: {
      left: 38px;
      top: 5px;
    }

    span {
      font: {
        size: 30px;
        weight: 600;
        style: italic;
      }

      color: $main-2-color;
      position: absolute;
      left: 0;
      top: 0;
    }

    h3 {
      font: {
        size: 18px;
        weight: 600;
      }

      margin-bottom: 10px;
    }
  }
}

/*================================================
Skill CSS
=================================================*/
.skill-image {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/skill-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.skill-content {
  background-color: $main-2-color;

  padding: {
    left: 100px;
    right: 100px;
  }

  .freelancer-section-title {
    text-align: left;
    margin-bottom: 35px;

    h2 {
      color: $white-color;

      span {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $black-color 80%);
      }
    }

    p {
      margin: {
        left: 0;
        right: 0;
      }

      color: $white-color;
      opacity: .95;
    }
  }

  .skills {
    position: relative;

    .skill-item {
      position: relative;
      margin-bottom: 25px;

      .skill-header {
        position: relative;
        margin-bottom: 10px;

        .skill-title {
          font: {
            size: 16px;
            weight: 600;
          }

          margin-bottom: 0;
          color: $white-color;
        }

        .skill-percentage {
          position: absolute;
          right: 0;
          top: 1px;

          font: {
            size: 15px;
            weight: 600;
          }

          color: $white-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .skill-bar {
        position: relative;
        width: 100%;

        .bar-inner {
          position: relative;
          width: 100%;

          border: {
            top: 3px solid $black-color;
            bottom: 3px solid $black-color;
          }

          border-radius: 30px;

          .bar {
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 6px;
            background: $white-color;
            transition: all 2000ms ease;
            border-radius: 30px;
            margin-top: -3px;
          }
        }
      }
    }
  }
}

/*================================================
Portfolio CSS
=================================================*/
.portfolio-area {
  position: relative;
  padding-bottom: 70px;
  z-index: 1;
}

.shorting-menu {
  text-align: center;
  margin-bottom: 40px;

  .filter {
    border: none;
    margin: 0 15px;
    position: relative;
    font-weight: 600;
    outline: 0 !important;
    box-shadow: unset !important;
    background: transparent;
    transition: $transition;
    z-index: 1;

    &::before {
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      background: #bbbbbb;
      content: '';
      position: absolute;
      left: -22px;
      top: 50%;
      width: 10px;
      margin-top: -4px;
      height: 10px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 12px;
      height: 2px;
      z-index: -1;
      background: $black-color;
      left: 3px;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      bottom: 8px;
      transform: rotate(0);
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:hover,
    &:focus,
    &.active {
      color: $main-2-color;

      &::after {
        opacity: 1;
        transform: rotate(45deg);
        visibility: visible;
      }
    }
  }
}

// .shorting {
//     .mix {
//         display: none;
//     }
// }
.single-work {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  a {
    &.popup-btn {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .work-image {
    position: relative;

    img {
      transition: $transition;
    }

    .work-overlay {
      position: absolute;
      overflow: hidden;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;

      &::before {
        content: '';
        transition: $transition;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: $main-2-color;
        right: -100%;
      }

      &::after {
        content: '';
        transition: $transition;
        position: absolute;
        background: $main-2-color;
        top: 0;
        height: 50%;
        width: 100%;
        left: -100%;
      }

      h3 {
        color: transparent;
        overflow: hidden;
        position: absolute;
        top: 30px;
        text-align: center;
        right: 25px;
        margin: 0;
        transition: $transition;

        font: {
          size: 20px;
          weight: 600;
        }

        z-index: 2;

        &::before {
          content: '';
          background: $black-color;
          position: absolute;
          width: 100%;
          bottom: 0;
          transition: $transition;
          left: 100%;
          top: 0;
          right: auto;
        }
      }

      span {
        color: transparent;
        overflow: hidden;
        z-index: 2;
        position: absolute;
        top: 62px;
        display: block;
        right: 25px;

        font: {
          weight: 600;
        }

        transition: $transition;
        text-align: center;

        &::before {
          content: '';
          background: $white-color;
          width: 100%;
          top: 0;
          bottom: 0;
          position: absolute;
          transition: $transition;
          right: 100%;
        }
      }
    }
  }

  &:hover,
  &:focus {
    .work-image {
      img {
        transform: scale(1.3);
      }

      .work-overlay {
        &::before {
          right: 0;
          opacity: 1;
          width: 100%;
          height: 50%;
          transition: all .8s;
        }

        &::after {
          left: 0;
          opacity: 1;
          transition: all .8s;
        }

        span {
          color: $white-color;

          &::before {
            right: -100%;
          }
        }

        h3 {
          color: $white-color;

          &::before {
            left: -100%;
          }
        }
      }
    }
  }
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/cta-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $black-color;
    opacity: .60;
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: '';
    z-index: -1;
    width: 100%;
    height: 20%;
    bottom: 0;
    left: 0;
    background: #f9f9f9;
  }
}

.cta-content {
  max-width: 580px;
  margin: 0 auto;
  text-align: center;

  h2 {
    font: {
      size: 30px;
      weight: 600;
    }

    color: $white-color;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 30px;
    color: $white-color;
  }

  .video-btn {
    width: 70px;
    height: 70px;
    line-height: 70px;
    color: $white-color;
    font-size: 25px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    display: inline-block;
    background: $main-2-color;

    &:hover,
    &:focus {
      background-color: $white-color;
      color: $main-2-color;
    }

    &::after {
      z-index: -1;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 70px;
      height: 70px;
      animation: ripple 1.6s ease-out infinite;
      opacity: 0;
      border-radius: 50%;
      background: rgba(255, 255, 255, .5);
    }
  }
}

.cta-inner-content {
  // margin-top: 100px;
  background: transparent;

  .single-cta-box {
    background: $white-color;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    text-align: center;
    padding: 40px 25px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1%;
      width: 100%;
      z-index: -1;
      background: $main-2-color;
      transition: $transition;
    }

    i {
      font-size: 45px;
      color: $main-2-color;
      transition: $transition;
    }

    h3 {
      font: {
        size: 20px;
        weight: 600;
      }

      margin: {
        top: 28px;
        bottom: 15px;
      }

      transition: $transition;
    }

    p {
      margin-bottom: 0;
      transition: $transition;
    }

    &:hover,
    &:focus {
      &::before {
        height: 100%;
      }

      i {
        color: $white-color;
      }

      h3 {
        color: $white-color;
      }

      p {
        color: $white-color;
      }
    }
  }
}

@keyframes ripple {

  0%,
  35% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/*vision*/
.vision {
  background-color: $main-2-color;
  height: 730px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .back-text {
    position: absolute;
    right: 20%;
    z-index: -1;
    top: 50%;
    transform: rotate(-40deg);
    margin-top: -75px;

    h1 {
      font-size: 155px;
      font-weight: 700;
      color: #fff;
      opacity: .10;
    }
  }
}

.vision-content {
  margin-top: 70px;
  text-align: center;

  h1 {
    margin: 0;
    color: $white-color;

    font: {
      size: 55px;
      weight: 700;
    }

    span {
      background: {
        image: url(../../images/bar.png);
        position: center center;
        size: contain;
        repeat: no-repeat;
      }
    }
  }

  p {
    margin: 20px 0 35px;
    padding: 0;
    color: $white-color;
  }
}

.vision-image {
  text-align: center;
}

/*CTA-style-two*/
.cta-area-two {
  background-color: $main-2-color;
}

.cta-content-two {
  max-width: 550px;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin: {
      bottom: 55px;
      top: -5px;
    }

    position: relative;
    color: $white-color;

    font: {
      size: 35px;
      weight: 600;
    }

    &::before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      background: $white-color;
      width: 70px;
      height: 3px;
      content: '';
      margin: 0 auto;
    }
  }

  .btn {
    padding: 14px 40px;
    text-transform: uppercase;
  }

  .btn-primary {
    background-color: $white-color;
    color: $black-color;

    &:hover,
    &:focus,
    &.active {
      background-color: $black-color;
      color: $white-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show>&.dropdown-toggle:focus {
      background-color: $black-color;
      color: $white-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show>&.dropdown-toggle {
      background-color: $black-color;
      color: $white-color;
      box-shadow: unset;
    }
  }
}

/*CTA-style-three*/
.cta-content-three {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  h3 {
    font: {
      size: 22px;
      weight: 600
    }

    margin: 30px 0 15px;
  }

  p {
    margin-bottom: 20px;
  }

  .btn-primary {
    background-color: transparent;
    color: $black-color;
    font-weight: 600;
    border: 1px solid $main-2-color;

    &:hover,
    &:focus,
    &.active {
      background-color: $main-2-color;
      color: $white-color;
      box-shadow: unset;
      border-color: $main-2-color;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show>&.dropdown-toggle:focus {
      background-color: $main-2-color;
      color: $white-color;
      box-shadow: unset;
      border-color: $main-2-color;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show>&.dropdown-toggle {
      background-color: $main-2-color;
      color: $white-color;
      box-shadow: unset;
      border-color: $main-2-color;
    }
  }
}

/*CTA-style-four*/
.cta-area-four {
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/cta-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $black-color;
    opacity: .80;
    z-index: -1;
  }
}

.cta-content-four {
  text-align: center;
  max-width: 620px;
  margin: 0 auto;

  h2 {
    font: {
      size: 40px;
      weight: 700;
    }

    line-height: 1.4;
    color: $white-color;
    margin-bottom: 0;
  }

  p {
    color: $white-color;
    font-size: 16px;
    margin: 20px 0 35px;
  }

  .btn {
    padding: 21px 55px;

    font: {
      size: 16px;
      weight: 600;
    }
  }

  .btn-primary {

    &:hover,
    &:focus,
    &.active {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show>&.dropdown-toggle:focus {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show>&.dropdown-toggle {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }
  }
}

/*CTA-style-five*/
.cta-content-five {
  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 12px;
    }
  }

  h2 {
    margin: {
      bottom: 15px;
    }

    font: {
      size: 30px;
      weight: 700;
    }

    line-height: 40px;
  }

  // p {
  //     margin: 0;
  // }
  .box {
    margin-top: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: $white-color;
    padding: 25px;
    transition: $transition;
    box-shadow: 0px 0px 50px 30px rgba(34, 34, 34, 0.05);

    i {
      color: $main-2-color;
      font-size: 35px;
      transition: $transition;
    }

    h3 {
      font: {
        size: 16px;
        weight: 600;
      }

      margin: {
        top: 15px;
        bottom: 0;
      }

      transition: $transition;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      background: $main-2-color;
      left: 0;
      height: 2px;
      transition: $transition;
      z-index: -1;
    }

    &:hover,
    :focus {
      transform: translateY(-5px);

      &::before {
        height: 100%;
      }

      h3 {
        color: $white-color;
      }

      i {
        color: $white-color;
      }
    }
  }
}

/*================================================
Funfacts CSS
=================================================*/
.single-funfact {
  text-align: center;

  h3 {
    font: {
      size: 35px;
      weight: 600;
    }

    margin-bottom: 0;

    .odometer-formatting-mark {
      display: none;
    }
  }

  p {
    margin: {
      bottom: 0;
      top: 5px;
    }

    font-weight: 500;
  }
}

/*funfacts-style-two*/
.funfacts-area-two {
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/cta-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: $black-color;
    opacity: .60;
    z-index: -1;
  }
}

.funfact {
  text-align: center;
  background-color: $white-color;
  padding: 30px;
  border-radius: 5px;
  margin: 0 0 20px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 10px;
    margin: 0 auto;
    text-align: center;
    bottom: -10px;
    width: 94%;
    z-index: -1;
    height: 100%;
    background: $white-color;
    opacity: .57;
  }

  &::after {
    content: '';
    position: absolute;
    left: 20px;
    margin: 0 auto;
    text-align: center;
    bottom: -20px;
    width: 86%;
    z-index: -1;
    height: 100%;
    background: $white-color;
    opacity: .57;
  }

  i {
    color: $main-2-color;
    font-size: 30px;
  }

  h3 {
    font: {
      size: 30px;
      weight: 600;
    }

    display: block !important;
    margin: 25px 0 8px;

    .odometer-formatting-mark {
      display: none;
    }
  }

  p {
    margin-bottom: 0;
  }
}

/*funfacts-style-three*/
.funfacts-area-three {
  position: relative;
  z-index: 1;

  .map-bg {
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    opacity: .22;
  }
}

.funfact-item {
  text-align: center;

  h3 {
    margin-bottom: 5px;
    color: $main-2-color;

    font: {
      size: 35px;
      weight: 600;
    }

    .odometer {
      &.odometer-auto-theme {
        position: relative;
        top: -3px;
      }
    }
  }
}

.contact-cta-box {
  margin: 80px auto 0;
  max-width: 600px;
  border: 1px dashed #ebebeb;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;

  h3 {
    font: {
      size: 20px;
      weight: 600;
    }

    margin-bottom: 6px;
  }

  p {
    margin-bottom: 0;
  }

  .btn {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/*funfacts-style-four*/
.erp-funfacts {
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/cta-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
}

.funfacts-inner {
  max-width: 300px;

  margin: {
    right: 150px;
    left: auto;
  }

  .single-funfacts-box {
    transition: $transition;
    padding: 25px;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: $black-color;
      transition: $transition;
      line-height: 1.5;
      margin: 0;
      font-weight: 600;
    }

    h3 {
      font: {
        size: 40px;
        weight: 600;
      }

      transition: $transition;
      color: $main-2-color;
      margin: 8px 0 0 3px;

      .odometer {
        position: relative;
        top: -2.5px;
      }
    }

    &:hover,
    &:focus {
      background-color: $main-2-color;

      h3 {
        color: $white-color;
      }

      p {
        color: $white-color;
        opacity: .88;
      }
    }
  }
}

/*================================================
Start Now CSS
=================================================*/
.start-now-content {
  text-align: center;
  margin-bottom: 45px;

  span {
    display: block;
    color: $main-2-color;

    font: {
      size: 14px;
      weight: 600;
    }

    text-transform: uppercase;

    margin: {
      top: -5px;
      bottom: 10px;
    }
  }

  h2 {
    margin: {
      bottom: 0;
      left: auto;
      right: auto;
    }

    max-width: 620px;

    font: {
      size: 30px;
      weight: 700;
    }
  }

  p {
    max-width: 620px;
    margin: 15px auto 35px;
  }
}

.dashboard-image {
  text-align: center;
}

/*================================================
Feedback CSS
=================================================*/
.feedback-item {
  text-align: center;
  position: relative;
  z-index: 1;

  p {
    margin: {
      bottom: 0;
      top: 25px;
    }

    font-style: italic;
  }

  .client-info {
    margin-top: 25px;

    h3 {
      font: {
        size: 18px;
        weight: 600;
      }

      margin-bottom: 7px;
    }

    span {
      display: block;
      font-size: 14px;
      color: $main-2-color;
    }
  }

  i {
    font-size: 135px;
    color: #999999;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50%;
    right: 0;
    margin: 0 auto;
    transform: translateY(-25%);
    opacity: .10;
  }
}

.feedback-slides {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: unset;
      }
    }
  }
}

/*feedback-style-two*/
.feedback-area-two {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 56%;
    background: linear-gradient(to bottom, #e1f0ff, #ebf3ff, #f3f7ff, #fafbff, #ffffff);
    content: '';
    width: 100%;
    transform: skewY(175deg);
  }
}

/*feedback-style-three*/
.testimonial-area {
  position: relative;
  z-index: 1;

  &.bg-image {
    background: {
      image: url(../../images/bg.jpg);
      position: center center;
      size: cover;
      repeat: no-repeat;
    }
  }
}

.testimonial-item {
  border-right: 3px solid $main-2-color;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
  padding: 30px 20px 30px 125px;
  margin-bottom: 30px;
  background: $white-color;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &::after,
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: -17px;

    border: {
      top: 25px solid $main-2-color;
      left: 25px solid transparent;
      right: 25px solid transparent;
    }
  }

  &::before {
    bottom: -4px;
    transform: rotate(45deg);
  }

  &::after {
    top: -4px;
    transform: rotate(135deg);
  }

  .client-image {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: 20px;

    img {
      border-radius: 50%;
    }
  }

  .testimonial-content {
    .client-info {
      margin-top: 20px;

      h3 {
        font: {
          size: 20px;
          weight: 600;
        }

        margin: 0;
      }

      span {
        display: block;
        color: $main-2-color;
        margin-top: 7px;
      }
    }
  }
}

.testimonial-slides {
  &.owl-theme {
    .owl-nav {
      &.disabled {
        +.owl-dots {
          margin-top: 25px;
          line-height: .01;
        }
      }
    }

    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          background: transparent;
          border: 1px solid #dfdfdf;
          display: block;
          transition: $transition;
          border-radius: 50%;
          position: relative;
        }

        &:hover,
        &:focus {
          outline: 0;
          box-shadow: unset !important;

          span {
            background: $main-2-color;
            border-color: $main-2-color;
          }
        }

        &.active {
          span {
            background: $main-2-color;
            border-color: $main-2-color;
          }
        }
      }
    }
  }
}

/*================================================
Download App CSS
=================================================*/
.download-content {
  h3 {
    margin-bottom: 20px;

    font: {
      size: 30px;
      weight: 600;
    }

    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 14px;
  }

  .download-btn {
    margin-top: 30px;

    a {
      position: relative;
      padding: 12px 35px 12px 65px;
      background: $main-2-color;
      color: $white-color;
      border-radius: 70px;
      display: inline-block;

      font: {
        weight: 300;
        size: 15px;
      }

      margin-right: 5px;
      box-shadow: 0 13px 27px 0 rgba(163, 48, 53, 0.25);

      i {
        position: absolute;
        left: 25px;
        top: 50%;
        font-size: 25px;
        margin-top: -12px;
      }

      span {
        display: block;

        font: {
          weight: 700;
          size: 16px;
        }
      }

      &:hover,
      &:focus {
        transform: translateY(-5px);
      }
    }
  }
}

/*================================================
Online Shopping CSS
=================================================*/
.shop-online-image {
  text-align: center;
  margin-bottom: 50px;
}

.single-item {
  padding-top: 50px;

  i {
    font-size: 15px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    background: $gradient-color;
    color: $white-color;
  }

  h3 {
    font: {
      size: 18px;
      weight: 600;
    }

    margin-top: 25px;
  }
}

/*================================================
FAQ CSS
=================================================*/
.faq {
  max-width: 720px;
  margin: 0 auto;
}

.accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: none !important;
  border-radius: 0 !important;

  .accordion__item {
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    display: block;
    margin-bottom: 15px;
    background-color: $white-color;
    transition: $transition;
    border: none;
    outline: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion__button {
      display: block;
      color: $black-color;
      padding: 12px 35px 12px 15px;
      position: relative;
      font-weight: 600;
      text-decoration: none;
      background-color: $white-color;
      border: none;
      transition: $transition;
      outline: 0;

      &:hover,
      &.active,
      &:focus {
        color: $white-color;
        background-color: $main-2-color;
      }

      i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.active {
        i {
          &::before {
            content: "\f068";
          }
        }
      }
    }
  }

  .accordion__item+.accordion__item {
    border: none;
  }

  .accordion-content {
    display: none;
    padding: 15px;

    &.show {
      display: block;
    }
  }
}

.faq-image {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/faq-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

/*================================================
Platform Connect CSS
=================================================*/
.platform-box {
  box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.35);
  transition: $transition;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  -ms-flex-align: center !important;
  align-items: center !important;
  flex-wrap: wrap;
  padding: 20px;

  h3 {
    font: {
      size: 18px;
      weight: 600;
    }

    margin: 0 0 0 20px;
  }

  &:hover,
  &:focus {
    transform: translateY(-7px);
  }
}

/*================================================
My Interests CSS
=================================================*/
.single-interests {
  text-align: center;
  padding: 20px;
  transition: $transition;
  background-color: $white-color;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

  i {
    color: $main-2-color;
    font-size: 30px;
    transition: $transition;
  }

  h3 {
    font: {
      size: 18px;
      weight: 600;
    }

    transition: $transition;
    margin: 20px 0 0;
  }

  &:hover,
  &:focus {
    transform: translateY(-8px);
    background-color: $main-2-color;

    i {
      color: $white-color;
    }

    h3 {
      color: $white-color;
    }
  }

  .active {
    transform: translateY(-8px);
    background-color: $main-2-color;

    i {
      color: $white-color;
    }

    h3 {
      color: $white-color;
    }
  }
}

.single-interests.active {
  text-align: center;
  padding: 20px;
  transition: $transition;
  background-color: $main-2-color;
  transform: translateY(-8px);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

  i {
    color: $white-color;
    font-size: 30px;
    transition: $transition;
  }

  h3 {
    color: $white-color;

    font: {
      size: 18px;
      weight: 600;
    }

    transition: $transition;
    margin: 20px 0 0;
  }

  &:hover,
  &:focus {
    transform: translateY(-8px);


    i {
      color: $white-color;
    }

    h3 {
      color: $white-color;
    }
  }

  .active {
    transform: translateY(-8px);
    background-color: $main-2-color;

    i {
      color: $white-color;
    }

    h3 {
      color: $white-color;
    }
  }
}

/*================================================
Pricing CSS
=================================================*/
.pricing-table {
  background-color: $white-color;
  text-align: center;
  border: 1px solid #e6e6e6;
  transition: $transition;
  padding: 25px 15px;

  .pricing-header {
    .icon {
      width: 130px;
      height: 130px;
      text-align: center;
      display: inline-block;
      line-height: 130px;
      // background-color: #eaf6fa;
      border-radius: 50%;

      img {
        width: 130px;
      }
    }

    h3 {
      position: relative;
      z-index: 1;

      font: {
        size: 22px;
        weight: 600;
      }

      margin: {
        top: 25px;
        bottom: 40px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -7px;
        width: 50px;
        height: 2px;
        background: $main-2-color;
      }
    }
  }

  .pricing-features {
    padding: 0 0 30px;
    margin: 0 0 30px;
    list-style-type: none;
    border-bottom: 1px solid #e6e6e6;

    li {
      margin-bottom: 12px;
      font-weight: 500;
      color: $paragraph-color;

      &.unactive {
        // color: #a5a5a5;
        color: #383838;
        text-decoration: line-through;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .price {
    font: {
      size: 22px;
      weight: 600;
    }

    color: #5f5f5f;
    margin-bottom: 25px;

    span {
      display: block;

      font: {
        size: 15px;
        weight: 500;
      }

      color: $paragraph-color;
    }
  }

  .btn {
    padding: 11px 34px;
    font-weight: 600;
    border: 1px solid $main-2-color;
    text-transform: uppercase;
  }

  .btn-primary {
    background-color: transparent;
    color: $black-color;

    &:hover,
    &:focus,
    &.active {
      background-color: $main-2-color;
      color: $white-color;
      border-color: $main-2-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show>&.dropdown-toggle:focus {
      background-color: $main-2-color;
      color: $white-color;
      box-shadow: unset;
      border-color: $main-2-color;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show>&.dropdown-toggle {
      background-color: $main-2-color;
      color: $white-color;
      box-shadow: unset;
      border-color: $main-2-color;
    }
  }

  &:focus,
  &.active {
    border-color: $white-color;
    box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.2);
    background-color: $main-2-color;

    h3 {
      color: $white-color;
    }

    li {
      color: $white-color;
    }

    .price {
      color: $white-color;

      span {
        color: $white-color;
      }
    }

    .btn-primary {
      background-color: $main-1-color;
      color: $white-color;
      border-color: $white-color;
      box-shadow: unset;
    }
  }
}

/*pricing-style-two*/
.single-pricing {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  background-color: $white-color;
  text-align: center;
  padding: 30px 20px;
  border-radius: 5px;
  border: 2px dashed #e5f1ff;
  transition: $transition;

  .pricing-header {
    .icon {
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      font-size: 30px;
      transition: $transition;
      color: $main-2-color;
      background-color: #f1ecff;
      transition: $transition;
    }

    h3 {
      font: {
        size: 18px;
        weight: 700;
      }

      margin: {
        bottom: 0;
        top: 25px;
      }
    }
  }

  .pricing-features {
    padding: 0 0 35px;
    margin: 30px 0 35px;
    list-style-type: none;
    transition: $transition;
    border-bottom: 2px dashed #e5f1ff;

    li {
      color: #616483;
      margin-bottom: 12px;

      i {
        color: $main-2-color;
        margin-right: 4px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .price {
    font: {
      size: 25px;
      weight: 600;
    }

    color: #484b6e;
    margin-bottom: 30px;

    span {
      font: {
        size: $font-size;
        weight: 500;
      }

      display: block;
      margin-top: 8px;
    }
  }

  .select-btn {
    display: inline-block;
    padding: 10px 32px;
    background: #e2e5ff;
    border-radius: 30px;
    color: $black-color;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &.active {
    .select-btn {
      background-color: $main-2-color;
      color: $white-color;
    }

    .pricing-features {
      border-color: $main-2-color;
    }

    .pricing-header {
      .icon {
        background-color: $main-2-color;
        color: $white-color;
      }
    }
  }
}

/*================================================
Free Trial CSS
=================================================*/
.free-trial-content {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  h3 {
    font: {
      size: 35px;
      weight: 600
    }

    margin: 30px 0 15px;
  }

  p {
    margin-bottom: 20px;
  }
}

/*================================================
Blog CSS
=================================================*/
.single-blog-post {
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: $white-color;

  .blog-post-content {
    padding: 30px 20px 25px;

    h3 {
      font: {
        size: 20px;
        weight: 600;
      }

      margin-bottom: 10px;
      line-height: 30px;

      a {
        color: $black-color;

        &:hover,
        &:focus {
          color: $main-2-color;
        }
      }
    }

    p {
      margin-bottom: 20px;
    }
  }

  .read-more-btn {
    color: $black-color;
    font-weight: 600;

    &:hover,
    &:focus {
      letter-spacing: 1px;
      color: $main-2-color;
    }
  }
}

/*blog-style-two*/
.single-blog-item {
  margin-bottom: 30px;
  background-color: $white-color;

  .blog-image {
    position: relative;
    overflow: hidden;

    a {
      display: block;

      img {
        transition: $transition;
      }
    }

    .post-tag {
      position: absolute;
      left: 0;
      top: 40px;
      transition: $transition;

      a {
        background: $main-2-color;
        color: $white-color;
        padding: 9px 28px;
      }
    }
  }

  .blog-post-content {
    padding: 25px;

    .date {
      color: $main-1-color;
      display: block;
      text-transform: uppercase;
      font-weight: 600;

      font: {
        size: 14px;
      }

      transition: $transition;
    }

    h3 {
      margin: {
        top: 13px;
        bottom: 15px;
      }

      line-height: 30px;

      font: {
        size: 20px;
        weight: 600;
      }

      a {
        color: $black-color;

        &:hover,
        &:focus {
          color: $main-1-color;
        }
      }
    }

    p {
      margin-bottom: 18px;
      text-align: justify;
    }
  }

  .read-more-btn {
    position: relative;
    font-weight: 500;
    color: $main-1-color;

    &:hover {
      color: $main-2-color;
      letter-spacing: 1px;
    }
  }

  &:hover,
  &:focus {
    .blog-image {
      a {
        img {
          transform: scale(1.2);
        }
      }

      .post-tag {
        top: 50px;

        a {
          background: $main-2-color;
          color: $white-color;
        }
      }
    }
  }
}

.blog-slides {
  &.owl-theme {
    .owl-nav {
      &.disabled {
        +.owl-dots {
          margin-top: 25px;
          line-height: .01;
        }
      }
    }

    .owl-dots {
      .owl-dot {
        span {
          width: 15px;
          height: 15px;
          margin: 0 4px;
          background: transparent;
          border: 1px solid #dfdfdf;
          display: block;
          transition: $transition;
          border-radius: 50%;
          position: relative;
        }

        &:hover,
        &:focus {
          outline: 0;
          box-shadow: unset !important;

          span {
            background: $main-2-color;
            border-color: $main-2-color;
          }
        }

        &.active {
          span {
            background: $main-2-color;
            border-color: $main-2-color;
          }
        }
      }
    }
  }
}

/*================================================
Contact CSS
=================================================*/
#contactForm {
  background: $white-color;
  padding: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid $main-2-color;

  label {
    color: $paragraph-color;
    font-weight: 500;
  }

  .btn {
    margin-top: 5px;
  }

  .with-errors {
    ul {
      li {
        color: red;
        margin-top: 5px;
      }
    }
  }

  .contact-submit {
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  #msgSubmit {
    margin: 0;
    font-size: 17px;

    &.text-danger,
    &.text-success {
      margin-top: 10px;
    }
  }
}

.contact-info-box {
  background: $black-color;
  padding: 30px 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

  div {
    padding: 0;
    list-style-type: none;
    margin: 0;

    div {
      position: relative;
      font-weight: 500;
      color: $paragraph-color;

      span {
        font: {
          size: 16px;
          weight: 600;
        }

        color: $white-color;
        display: block;
        margin-bottom: 5px;
      }

      a {
        color: $white-2-color;

        &:hover,
        &:focus {
          color: $white-color;
        }
      }

      i {
        left: 0;
        top: 5px;
        padding-bottom: 15px;
        font-size: 30px;
        color: $main-2-color;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*contact-style-two*/
.contact-content {
  h3 {
    margin: {
      bottom: 20px;
      top: -5px;
    }

    position: relative;
    z-index: 1;

    font: {
      size: 30px;
      weight: 600;
    }

    span {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-2-color 80%);
      background-size: 90px 1.1em !important;
    }
  }

  h6 {
    margin-bottom: 12px;
    font-weight: 600;
    line-height: 1.7;
  }

  p {
    margin: 0;
  }
}

.freelancer-contact-form {
  background: $white-color;
  padding: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

  .btn {
    margin-top: 5px;
    display: block;
    width: 100%;
    border-radius: 0;
  }

  .with-errors {
    ul {
      li {
        color: red;
        margin-top: 5px;
      }
    }
  }

  #msgSubmit {
    margin: 0;
    font-size: 17px;

    &.text-danger,
    &.text-success {
      margin-top: 10px;
    }
  }
}

/*contact-style-four*/
.car-subscription-contact-area {
  padding: {
    bottom: 100px;
  }
}

.contact-box {
  h3 {
    font: {
      size: 24px;
      weight: 600;
    }

    margin-bottom: 25px;
  }

  .btn {
    border-radius: 5px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;

      a {
        border-radius: 5px;
        font-weight: 600;
        padding: 11px 30px;
        border: 1px solid #dee2e6;
        color: $main-2-color;

        &:hover,
        &:focus {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*contact-style-five*/
.erp-contact {
  background-color: $main-2-color;

  padding: {
    top: 50px;
    bottom: 50px;
  }
}

.contact-content-box {
  h3 {
    font: {
      size: 30px;
      weight: 700;
    }

    color: $white-color;
    margin-bottom: 10px;
  }

  p {
    color: $white-color;
    opacity: .88;
  }
}

.contact-connect {
  text-align: right;

  a {
    display: inline-block;
  }

  .btn-primary {
    color: $white-color;
    background-color: $black-color;

    &:hover,
    &:focus,
    &.active {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show>&.dropdown-toggle:focus {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show>&.dropdown-toggle {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }
  }

  .talk-to-sales {
    margin-left: 10px;

    font: {
      weight: 600;
      size: 15px;
    }

    border: 2px solid $white-color;
    padding: 13px 34px;
    border-radius: 60px;
    background: transparent;
    color: $white-color;

    &:hover,
    &:focus {
      color: $black-color;
      background-color: $white-color;
    }
  }
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: $main-2-color;

  &:hover,
  &:focus {
    .rocket {
      img {
        transform: rotate(-28deg);
      }
    }
  }
}

.rocket {
  position: absolute;
  right: 5%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);

  img {
    transition: $transition;
  }

  &:hover,
  &:focus {
    img {
      transform: rotate(-26deg);
    }
  }
}

.map {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.subscribe-content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin: {
      bottom: 10px;
      top: -5px;
    }

    color: $white-color;

    font: {
      size: 30px;
      weight: 600;
    }
  }

  p {
    color: $white-color;
    opacity: .88;
    margin: 0;
  }
}

.newsletter-form {
  margin-top: 30px;

  .form-control {
    border: none;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border-radius: 30px;
    height: 60px;
  }

  .row {
    margin: {
      left: -5px;
      right: -5px;
    }

    .col-lg-8,
    .col-lg-4 {
      padding: {
        left: 5px;
        right: 5px;
      }
    }
  }

  button {
    display: block;
    width: 100%;
    outline: 0;
    height: 60px;
    border-radius: 30px;
    background: $main-2-color;
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    border: none;
    color: $white-color;

    font: {
      size: 16px;
      weight: 600;
    }

    transition: $transition;

    &:hover,
    &:focus {
      background-color: $white-color;
      color: $main-2-color;
    }
  }

  #validator-newsletter,
  .validation-success {
    color: $white-color;
    margin-top: 15px;
  }

  .recaptcha {
    &>div {
      &>div {
        margin: 0 auto;
      }
    }
  }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
    image: url(../../images/agency-main-bg.jpg);
  }

  position: relative;
  z-index: 1;
  text-align: center;

  padding: {
    top: 200px;
    bottom: 120px;
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(13, 15, 20, 0.75);
    width: 100%;
    height: 100%;
    z-index: -1;
    content: '';
  }

  h2 {
    font: {
      size: 40px;
      weight: 700;
    }

    margin-bottom: 0;
    color: $white-color;
  }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 30px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      a {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 5px;
        background-color: $white-color;
        border-radius: 0 !important;
        text-align: center;
        line-height: 40px;
        color: $black-color;

        font: {
          size: 17px;
          weight: 600;
        }

        box-shadow: 0 2px 10px 0 #d8dde6;
        border: none;

        &.active,
        &:hover,
        &:focus {
          background: $main-2-color;
          color: $white-color;
          box-shadow: unset;
        }
      }
    }
  }
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar {
  .widget {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .widget-title {
      font: {
        size: 20px;
        weight: 600;
      }

      margin-bottom: 25px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 2px;
        width: 17px;
        height: 17px;
        background: $main-2-color;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        transition: $transition;
      }
    }

    &:hover,
    &:focus {
      .widget-title {
        &::before {
          transform: rotate(180deg);
        }
      }
    }

    &.widget_search {
      background: $white-color;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
      padding: 15px;

      form {
        position: relative;

        .form-control {
          background: transparent;
        }

        button {
          position: absolute;
          right: 5px;
          top: 0;
          height: 100%;
          border: none;
          outline: 0;
          box-shadow: unset;
          background: transparent;
          color: $main-2-color;
          transition: $transition;

          &:hover,
          &:focus {
            color: $black-color;
          }
        }
      }
    }

    &.widget_categories {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          border-top: 1px solid #eeeeee;

          padding: {
            top: 10px;
            bottom: 10px;
            left: 18px;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 10px;
            height: 10px;
            background: $main-2-color;
            margin-top: -5px;
            transition: $transition;
          }

          &:last-child {
            border-bottom: 1px solid #eeeeee;
          }

          a {
            color: $paragraph-color;

            &:hover {
              color: $main-2-color;
            }
          }

          &:hover {
            &::before {
              border-radius: 50%;
            }
          }
        }
      }
    }

    &.widget_recent_entries {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          padding-left: 100px;
          margin-bottom: 15px;

          a {
            display: block;

            img {
              position: absolute;
              left: 0;
              top: 3px;
              height: 65px;
            }
          }

          h5 {
            font: {
              size: 15px;
              weight: 600;
            }

            line-height: 22px;
            margin-bottom: 3px;

            a {
              color: $black-color;
              display: inline-block;

              &:hover,
              &:focus {
                color: $main-2-color;
              }
            }
          }

          p {
            font-size: 14px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.widget_tag_cloud {
      .tagcloud {
        a {
          font: {
            size: 15px !important;
          }

          border: 1px dashed #eeeeee;
          padding: 7px 20px;
          margin-top: 6px;
          color: $paragraph-color;
          display: inline-block;

          &:hover {
            background: $main-2-color;
            color: $white-color;
            border-color: $main-2-color;
          }
        }
      }
    }

    &.widget_archive {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          border-top: 1px solid #eeeeee;

          padding: {
            top: 10px;
            bottom: 10px;
            left: 18px;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 10px;
            height: 10px;
            background: $main-2-color;
            margin-top: -5px;
            transition: $transition;
          }

          &:last-child {
            border-bottom: 1px solid #eeeeee;
          }

          a {
            color: $paragraph-color;
            display: inline-block;

            &:hover {
              color: $main-2-color;
            }
          }

          &:hover {
            &::before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details {
  .post-image {
    margin-bottom: 35px;
  }

  h3 {
    font: {
      size: 22px;
      weight: 600;
    }

    margin: {
      top: 5px;
      bottom: 15px;
    }
  }

  .blog-meta {
    ul {
      padding: 0;
      margin: 20px 0;
      list-style-type: none;

      li {
        display: inline-block;
        position: relative;
        color: $paragraph-color;

        font: {
          size: 14px;
        }

        margin: {
          left: 9px;
          right: 9px;
        }

        i {
          margin-right: 4px;
          color: $main-2-color;
        }

        &::before {
          width: 2px;
          height: 14px;
          background: $paragraph-color;
          left: -10px;
          top: 50%;
          position: absolute;
          content: '';
          margin-top: -6px;
          transform: rotate(11deg);
        }

        a {
          display: inline-block;
          color: $paragraph-color;

          &:hover,
          &:focus {
            color: $main-2-color;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  p {
    margin: {
      top: 15px;
      bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote,
  .blockquote {
    background: #f6f6f6 none repeat scroll 0 0;
    padding: 35px 20px 35px 25px;
    position: relative;
    border-left: 5px solid $main-2-color;

    margin: {
      top: 25px;
      bottom: 25px;
    }

    p {
      color: $black-color;

      font: {
        size: 18px;
        weight: 600;
      }

      margin: 0;
    }
  }
}

.post-tag-media {
  background-color: #f5f7fb;
  padding: 20px;

  margin: {
    top: 25px;
    bottom: 35px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;

      span {
        font: {
          weight: 600;
        }

        margin-right: 5px;
        display: inline-block;
      }

      a {
        color: $paragraph-color;
        margin-right: 4px;

        &:hover,
        &:focus {
          color: $main-2-color;
        }
      }
    }

    &.social-share {
      text-align: right;
    }
  }
}

.comments-area {

  .comments-title,
  .comment-reply-title {
    font: {
      size: 22px;
      weight: 600;
    }

    margin-bottom: 25px;
  }

  .comment-reply-title {
    margin-bottom: 8px;
  }

  ol,
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {

      ol,
      ul {
        li {
          margin: {
            top: 15px;
            left: 35px;
            bottom: 15px;
          }
        }
      }

      .comment-body {
        background: #f7f7ff;
        padding: 25px 20px;
        position: relative;

        .comment-meta {
          position: relative;

          padding: {
            left: 85px;
            top: 8px;
          }

          .comment-author {
            img {
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 50%;
              max-width: 68px;
            }

            .fn {
              font: {
                size: 16px;
                weight: 600;
              }

              margin-bottom: 5px;
              display: block;
            }

            .says {
              display: none;
            }
          }

          .comment-metadata {
            a {
              display: inline-block;
              color: $paragraph-color;
              font-size: 14px;

              &:hover,
              &:focus {
                color: $main-2-color;
              }
            }
          }
        }

        .comment-content {
          margin: {
            top: 25px;
          }
        }

        .reply {
          position: absolute;
          right: 30px;
          top: 30px;

          a {
            display: inline-block;
            background: $black-color;
            padding: 5px 15px;
            color: $white-color;

            &:hover,
            &:focus {
              background: $main-2-color;
            }
          }
        }
      }
    }
  }

  .comment-respond {
    margin-top: 30px;

    .comment-notes {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .comment-form-comment,
    .comment-form-author,
    .comment-form-email,
    .comment-form-url {

      input,
      textarea {
        display: block;
        width: 100%;
        height: 45px;
        outline: 0 !important;
        box-shadow: unset !important;
        border: 1px solid #eeeeee;
        padding: 15px;
        border-radius: 3px;
        transition: $transition;

        &:focus {
          border-color: $main-2-color;
        }
      }

      textarea {
        height: auto;
      }
    }

    .form-submit {
      input {
        display: inline-block;
        background: $main-2-color;
        border: none;
        color: $white-color;
        padding: 12px 30px;
        text-transform: uppercase;
        font-weight: 600;
        outline: 0 !important;
        margin-top: 5px;
        transition: $transition;

        &:hover,
        &:focus {
          background: $black-color;
          box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
        }
      }
    }
  }
}

/*================================================
404 Error CSS
=================================================*/
.error-content {
  margin: 0 auto;
  max-width: 650px;

  .search-form {
    position: relative;
    margin-top: 45px;

    .search-field {
      display: block;
      width: 100%;
      height: 50px;
      outline: 0;
      box-shadow: unset;
      padding-left: 15px;
      transition: $transition;
      text-transform: capitalize;
      border: 1px solid #eeeeee;

      &:hover,
      &:focus {
        border-color: $main-2-color;
      }
    }

    .search-submit {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 50px;
      width: 110px;
      outline: 0;
      box-shadow: unset;
      border: none;
      background: $main-2-color;
      color: $white-color;
      font-weight: 600;
      transition: $transition;

      &:hover,
      &:focus {
        background-color: $black-color;
        color: $white-color;
      }
    }
  }
}

/*================================================
Login CSS
=================================================*/
.login-area {
  height: 100vh;
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/agency-main-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    opacity: .77;
  }

  .login-form {
    max-width: 540px;
    background: $white-color;
    margin: 0 auto;

    h3 {
      background: $main-2-color;
      padding: 30px 0 25px;

      font: {
        size: 24px;
        weight: 700;
      }

      margin-bottom: 0;
      color: $white-color;
      text-align: center;
      text-transform: uppercase;
    }

    form {
      padding: 35px;

      label {
        font: {
          weight: 500;
        }
      }

      .form-control {
        background: transparent;
        font-size: 13px;
      }
    }

    .btn {
      display: block;
      width: 100%;
    }

    p {
      overflow: hidden;

      margin: {
        bottom: 0;
        top: 20px;
      }

      a {
        color: $black-color;

        font: {
          weight: 600;
        }

        &:hover,
        &:focus {
          color: $main-2-color;
        }

        &.pull-left {
          float: left;
        }

        &.pull-right {
          float: right;
        }
      }
    }
  }
}

/*================================================
Signup CSS
=================================================*/
.signup-area {
  height: 100vh;
  position: relative;
  z-index: 1;

  background: {
    image: url(../../images/agency-main-bg.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: $gradient-color;
    opacity: .77;
  }

  .signup-form {
    max-width: 540px;
    background: $white-color;
    margin: 0 auto;

    h3 {
      background: $main-2-color;
      padding: 30px 0 25px;

      font: {
        size: 24px;
        weight: 700;
      }

      margin-bottom: 0;
      color: $white-color;
      text-align: center;
      text-transform: uppercase;
    }

    form {
      padding: 35px;

      label {
        font: {
          weight: 500;
        }
      }

      .form-control {
        background: transparent;
        font-size: 13px;
      }
    }

    .btn {
      display: block;
      width: 100%;
    }

    p {
      text-align: center;
      color: $black-color;

      margin: {
        bottom: 0;
        top: 20px;
      }

      a {
        color: $black-color;
        display: inline-block;

        font: {
          weight: 600;
        }

        &:hover,
        &:focus {
          color: $main-2-color;
        }
      }
    }
  }
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon {
  position: relative;
  z-index: 1;
  height: 100vh;
  text-align: center;

  background: {
    image: url(../../images/agency-main-bg.jpg);
    position: center center;
    size: cover;
    attachment: fixed;
    repeat: no-repeat;
  }

  &::before {
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $gradient-color2;
    opacity: .80;
    position: absolute;
    content: '';
  }

  .coming-soon-content {
    max-width: 650px;
    margin: 0 auto;

    h1 {
      color: $white-color;

      font: {
        size: 45px;
        weight: 900;
      }

      text-transform: capitalize;
      margin-bottom: 0;
      line-height: 57px;
    }

    p {
      color: $white-color;

      margin: {
        top: 20px;
        bottom: 35px;
      }
    }

    .newsletter-form {
      position: relative;

      .form-control {
        background: transparent;
        border-radius: 0;
        border: 1px solid $white-color;
        box-shadow: unset;
        color: $white-color;
        height: 65px;

        &::placeholder {
          color: $white-color;
        }
      }

      button {
        position: absolute;
        right: -1px;
        height: 100%;
        background: $main-2-color;
        box-shadow: unset;
        top: 0;
        color: $white-color;
        border: none;
        font-size: 14px;
        width: 130px;
        border-radius: 0;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        transition: $transition;
        outline: 0 !important;
        border: none;

        &:hover,
        &:focus {
          background: $main-2-color;
        }
      }

      #validator-newsletter,
      .validation-success {
        color: $white-color;
        position: absolute;
        left: 0;
        bottom: -35px;
      }
    }

    #timer {
      margin-bottom: 35px;

      div {
        display: inline-block;

        font: {
          size: 45px;
          weight: 700;
        }

        color: $white-color;
        width: 135px;
        height: 135px;
        border: 1px solid #eeeeee;
        border-radius: 50%;
        padding-top: 34px;
        line-height: 40px;
        margin: 0 5px;
      }

      span {
        display: block;

        font: {
          size: 16px;
          weight: 400;
        }
      }
    }
  }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
  position: relative;
  padding-top: 100px;
  z-index: 1;
  background-color: $black-color;

  h3 {
    color: $white-color;
  }
}

.map2 {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  text-align: center;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}

.single-footer-widget {
  .logo {
    margin-bottom: 15px;

    a {
      display: inline-block;

      img {
        height: 60px;
        margin-top: -20px;
        margin-left: -15px;
      }
    }
  }

  .contact-info {
    padding: 0;
    margin: 25px 0 0;
    list-style-type: none;

    li {
      color: $white-2-color;
      margin-bottom: 10px;
      font-weight: 500;

      a {
        color: $white-2-color;

        &:hover,
        &:focus {
          color: $white-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    font: {
      size: 19px;
      weight: 600;
    }

    margin-bottom: 35px;
    position: relative;
    z-index: 1;

    &::before {
      position: absolute;
      left: 0;
      bottom: -7px;
      width: 50px;
      height: 2px;
      z-index: -1;
      content: '';
      background: $main-2-color;
    }
  }

  ul {
    &.list {
      padding: 0;
      list-style-type: none;
      padding: 0;

      li {
        color: $paragraph-color;
        margin-bottom: 12px;

        font: {
          weight: 500;
          size: 14px;
        }

        a {
          color: $white-2-color;
          font-size: 14px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $main-2-color;
            width: 10px;
            height: 2px;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
          }

          &:hover,
          &:focus {
            color: $main-2-color;
            padding-left: 15px;

            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.copyright-area {
  margin-top: 80px;
  border-top: 1px solid #f5f3ec;

  padding: {
    top: 30px;
    bottom: 30px;
  }

  p {
    a {
      display: inline-block;
      color: $paragraph-color;

      &:hover,
      &:focus {
        color: $main-2-color;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    text-align: right;

    li {
      display: inline-block;
      margin-right: 5px;

      a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid $main-1-color;
        color: $main-2-color;
        border-radius: 50%;
        text-align: center;

        &:hover,
        &:focus {
          color: $white-color;
          background-color: $main-2-color;
          transform: translateY(-5px);
          border-color: $main-1-color;
        }
      }
    }
  }
}

/*footer-style-two*/
.footer-area-two {
  background-color: #222222;

  padding: {
    top: 40px;
    bottom: 40px;
  }

  .bar {
    border: .5px solid #333333;

    margin: {
      top: 30px;
      bottom: 30px;
    }
  }

  .contact-info {
    text-align: center;

    h4 {
      font: {
        size: 18px;
        weight: 600;
      }

      margin-bottom: 10px;
      color: $white-color;
    }

    span {
      display: block;
      color: $white-color;

      a {
        color: $white-color;

        &:hover,
        &:focus {
          color: $main-2-color;
        }
      }
    }
  }

  p {
    color: $white-color;

    a {
      display: inline-block;
      color: $white-color;

      &:hover,
      &:focus {
        color: $main-2-color;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    text-align: right;

    li {
      margin-left: 4px;
      display: inline-block;

      a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ffe8e6;
        color: $white-color;
        border-radius: 50%;
        text-align: center;

        &:hover,
        &:focus {
          color: $white-color;
          background-color: $main-2-color;
          transform: translateY(-5px);
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*footer-area-three*/
.footer-area-three {
  position: relative;

  padding: {
    top: 30px;
    bottom: 30px;
  }

  z-index: 1;
  background-color: #fffcf4;

  p {
    a {
      display: inline-block;
      color: $paragraph-color;

      &:hover,
      &:focus {
        color: $main-2-color;
      }
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    text-align: right;

    li {
      display: inline-block;

      a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ffe8e6;
        color: #646464;
        border-radius: 50%;
        text-align: center;

        &:hover,
        &:focus {
          color: $white-color;
          background-color: $main-2-color;
          transform: translateY(-5px);
          border-color: $main-2-color;
        }
      }
    }
  }
}

/*footer-style-four*/
.footer-area-four {
  position: relative;
  padding-top: 100px;
  z-index: 1;
  background-color: #222222;

  .single-footer-widget {
    .logo {
      a {
        display: inline-block;
      }
    }

    .contact-info {
      padding: 0;
      margin: 25px 0 0;
      list-style-type: none;

      li {
        color: $white-color;
        margin-bottom: 10px;
        font-weight: 500;

        a {
          color: $white-color;

          &:hover,
          &:focus {
            color: $main-2-color;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    h3 {
      font: {
        size: 18px;
        weight: 600;
      }

      color: $white-color;
      margin-bottom: 40px;
      position: relative;
      z-index: 1;

      &::before {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 50px;
        height: 2px;
        z-index: -1;
        content: '';
        background: $main-2-color;
      }
    }

    ul {
      &.list {
        padding: 0;
        list-style-type: none;
        padding: 0;

        li {
          color: $white-color;
          margin-bottom: 12px;

          font: {
            weight: 500;
            size: 14px;
          }

          a {
            color: $white-color;
            font-size: 14px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background: $main-2-color;
              width: 10px;
              height: 2px;
              opacity: 0;
              visibility: hidden;
              transition: $transition;
            }

            &:hover,
            &:focus {
              color: $main-2-color;
              padding-left: 15px;

              &::before {
                opacity: 1;
                visibility: visible;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .copyright-area {
    margin-top: 80px;
    border-top: 1px solid #564c4b;

    padding: {
      top: 30px;
      bottom: 30px;
    }

    p {
      color: $white-color;

      a {
        display: inline-block;
        color: $white-color;

        &:hover,
        &:focus {
          color: $main-2-color;
        }
      }
    }

    ul {
      text-align: right;

      li {
        display: inline-block;
        margin-right: 5px;

        a {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #564c4b;
          color: $white-color;
          border-radius: 50%;
          text-align: center;

          &:hover,
          &:focus {
            color: $white-color;
            background-color: $main-2-color;
            transform: translateY(-5px);
            border-color: $main-2-color;
          }
        }
      }
    }
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 8rem;
  right: 2rem;
  font-size: 25px;
  color: $white-color;
  background-color: $black-color;
  z-index: 1;
  // display: none;
  width: 60px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  transition: $transition;

  &:hover {
    color: $white-color;
    background: $main-2-color;
  }
}

// Progress Bar
.react-sweet-progress-symbol {
  color: #fff !important;
  font-weight: 600 !important;
}

.with-errors>.invalid-feedback {
  display: block;
}

.Terms,
.Privacy {
  text-align: justify;
  line-height: 27px;
}

.Subscription {
  margin-top: 40px;
}

.service-button-container {
  text-align: center;
  margin-top: 20px;
}

.features {
  .single-features-box {
    i {
      &.fa-cash-register {
        color: orange;
      }

      &.fa-industry {
        color: #009688;
      }

      &.fa-users {
        color: lightgreen;
      }

      &.fa-calendar-alt {
        color: red;
      }

      &.fa-bus {
        color: #fbbd05;
      }

      &.fa-wrench {
        color: darkblue;
      }

      &.fa-graduation-cap {
        color: #af863c;
      }

      &.fa-clinic-medical {
        color: blue;
      }

      &.fa-concierge-bell {
        color: #ffeb36;
      }

      &.fa-building {
        color: #5485bb;
      }

      &.fa-grin-hearts {
        color: #b8649f;
      }

      &.fa-utensils {
        background: -webkit-linear-gradient(left, red 55%, blue 55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .single-features-box:hover {
    i {
      color: white;

      &.fa-utensils {
        background: unset;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
      }
    }
  }
}

.feature-detail {
  ul {
    list-style: none;
    padding-left: 15px;

    i {
      margin-right: 5px;
    }
  }
}


.doc-container {
  margin-bottom: 30px;

  .jumbotron-fluid {
    .card {
      margin-bottom: 30px;
    }
  }

  .doc-body {
    h2 {
      margin-top: 15px;
    }

    img {
      border: 2px solid #000;
      margin-bottom: 15px;
    }
  }
}

.docs-saas-container {
  .go-back {
    text-align: right;
  }
}

.share-doc {
  position: relative;
  float: right;
  cursor: pointer;

  span {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
}

/*payment-processing-banner*/
.payment-processing-banner {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background: $main-2-color;

  }

}

.payment-processing-banner-content {
  .btn-box {
    a {
      display: inline-block;
      color: #fff !important;
      margin: 5px;
    }

    .video-btn {
      display: inline-block;
      color: #999999;
      text-transform: lowercase;
      font-weight: 600;
      margin-left: 30px;
      position: relative;
      top: 5px;

      i {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid #999999;
        transition: $transition;
      }

      &:hover,
      &:focus {
        color: $main-2-color;

        i {
          background-color: $main-2-color;
          color: $white-color;
          border-color: $main-2-color;
        }
      }
    }
  }

  padding: {
    top: 120px;
    right: 30px;
    bottom: 120px;
  }

  max-width: 75%;

  margin: {
    left: auto;
  }

  h1 {
    color: $white-color;
    margin: 0;

    font: {
      size: 40px;
      weight: 700;
    }
  }

  p {
    margin: 20px 0;
    opacity: .90;
    color: $white-color;
    font-size: 16px;
  }

  .btn {
    text-transform: uppercase;

    font: {
      weight: 600;
      size: 14px;
    }
  }

  .btn-primary {
    color: $white-color;
    background-color: $black-color;

    &:hover,
    &:focus,
    &.active {
      background-color: $main-1-color;
      color: $black-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show>&.dropdown-toggle:focus {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show>&.dropdown-toggle {
      background-color: $white-color;
      color: $black-color;
      box-shadow: unset;
    }
  }

  ul {
    padding: 0;
    margin: 0 0 35px;
    list-style-type: none;

    li {
      position: relative;
      margin-bottom: 12px;
      color: $white-color;
      font-weight: 500;
      padding-left: 15px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: $white-color;
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// .payment-processing-banner-image{
//     width: 100%;
//     height: 100%;
//     background: {
//         image: url(../../images/saas/web2.jpg);
//         position: center center;
//         size: cover;
//         repeat: no-repeat;
//     }
//     img {
//         display: none;
//     }
// }
.payment-processing-banner-image.web {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banWeb.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.ecommerce {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banEcommerce.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.catalog {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/saas/web2.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.erp {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banERP.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.mail {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banMail.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.check {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banCheckList.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.fleet {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banFleet.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.iot {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banIot.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.payment-processing-banner-image.otherApp {
  width: 100%;
  height: 100%;

  background: {
    image: url(../../images/banOtherApp.png);
    position: center center;
    size: 100%;
    repeat: no-repeat;
  }

  img {
    display: none;
  }
}

.pricing-area p {
  text-align: center;
}

.pd {
  margin-top: 100px;
}

.ventajas-title {
  text-align: center;
  margin-bottom: 60px;

  h2 {
    margin: {
      bottom: 0;
      top: -5px;
      left: auto;
      right: auto;
    }

    max-width: 520px;

    font: {
      size: 28px;
      weight: 700;
    }
  }

  .bar {
    width: 70px;
    height: 3px;
    background: $main-2-color;
    position: relative;
    left: 0;
    right: 0;
    border-radius: 5px;
    margin: 15px auto 15px;
  }

  p {
    max-width: 520px;
    margin: 0 auto;
  }
}

//table erp

.table-erp {
  .section-title {
    .title-text {
      h2 {
        margin: 0px 0 15px;
        font-weight: bold;
      }
    }
  }

  ul.custom-tab {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 30px;

    li {
      margin-right: 70px;
      position: relative;

      a {
        color: #252525;
        font-size: 25px;
        line-height: 25px;
        font-weight: 600;
        text-transform: capitalize;
        padding: 35px 0;
        position: relative;

        &:hover {
          &:before {
            width: 100%;
          }
        }

        &:before {
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          background: #4125dd;
          width: 0;
          height: 2px;
          -webkit-transition: all 0.4s;
          -o-transition: all 0.4s;
          transition: all 0.4s;
        }
      }

      a.active {
        color: #4125dd;
      }
    }
  }

  .primary-btn {
    margin-top: 40px;
  }

    .table {
      -webkit-box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
      margin-bottom: 0;

      thead {
        background-color: $black-color;
        color: #fff;
        tr {
          th {
            padding: 15px;
            border: 0;
          }
        }
      }

      tbody {
        background: #fff;

        tr.inner-box {
          border-bottom: 1px solid #dee2e6;
        }

        tr {
          th {
            border: 0;
            padding: 15px;
            vertical-align: middle;

            .event-date {
              color: #252525;
              text-align: center;

              span {
                font-size: 50px;
                line-height: 50px;
                font-weight: normal;
              }
            }
          }

          td {
            padding: 15px;
            vertical-align: middle;

            .r-no {
              span {
                color: #252525;
              }
            }

            .table-item {
              h3 {
                a {
                  font-size: 20px;
                  line-height: 20px;
                  color: #cf057c;
                  -webkit-transition: all 0.4s;
                  -o-transition: all 0.4s;
                  transition: all 0.4s;

                  &:hover {
                    color: #4125dd;
                  }
                }
              }

              .categories {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                margin: 10px 0;

                a {
                  color: #252525;
                  font-size: 16px;
                  margin-left: 10px;
                  -webkit-transition: all 0.4s;
                  -o-transition: all 0.4s;
                  transition: all 0.4s;

                  &:before {
                    content: "\f07b";
                    padding-right: 5px;
                  }
                }
              }

              .time {
                span {
                  color: #252525;
                }
              }

              .organizers {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                margin: 10px 0;

                a {
                  color: #4125dd;
                  font-size: 16px;
                  -webkit-transition: all 0.4s;
                  -o-transition: all 0.4s;
                  transition: all 0.4s;

                  &:hover {
                    color: #4125dd;
                  }

                  &:before {
                    content: "\f007";
                    padding-right: 5px;
                  }
                }
              }
            }

            .primary-btn {
              margin-top: 0;
              text-align: center;
            }

            .event-img {
              img {
                width: 100px;
                height: 100px;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
}
//wspButton
.floating-whatsapp-chatbox{
  height: 0;
  width: 0;
  z-index: 1;

  p{
    text-align: left;
  }
}